import { PageTitle } from "Layouts/Default"
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { StorageCard, LicenseCard, EditorCard, UsersCard, VideoCard, AudioCard } from "./Cards"

const Container = styled.div`
  display: flex;

  //align-items: center;
  justify-content: center;

  //height: 100%;

  //margin-top: 5em;

  .card-list {

    margin-top: 3rem;
    --card-min-width: 25em;

    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, var(--card-min-width));
    grid-auto-rows: 13rem;
  }
`

export default function DashboardPage() {

	const navigate = useNavigate();

	return (
		<>
			<PageTitle> Dashboard </PageTitle>
			<Container>
				<div className="card-list">
					<LicenseCard onClick={() => navigate('/license')} />
					<UsersCard onClick={() => navigate('/users')}/>
					<StorageCard onClick={() => navigate('/license')} />
					<VideoCard onClick={() => navigate('/license')} />
					<AudioCard onClick={() => navigate('/license')} />
					<EditorCard onClick={() => navigate('/license')} />
				</div>
			</Container>
		</>
	)
}