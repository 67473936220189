import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {LicenseWithLockedDevices} from "Models";

export interface RequestSubscribeData
{
    "priceId": string,
    "organizationId"?: string,
    "returnUrl": string
}

export interface LinkOutputDto
{
    url: string
}

export interface ManageSubscriptionDto
{
    licenseId : string
    returnUrl: string
}

export default {

    subscribeFree: createAsyncThunk<LicenseWithLockedDevices, RequestSubscribeData>('subscription/subscribeFree', async (subInput: RequestSubscribeData) =>
    {
        return await axios.post<LicenseWithLockedDevices>(`subscription/subscribeFree`, subInput).then(res => res.data);
    }),

    startTrial: createAsyncThunk<LinkOutputDto, RequestSubscribeData>('subscription/startTrial', async (subInput: RequestSubscribeData) =>
    {
        return await axios.post<LinkOutputDto>(`subscription/startTrial`, subInput).then(res => res.data);
    }),

    cancelFreeSubscription: createAsyncThunk<void, string>('subscription/cancelFreeSubscription', async (licenseId: string) =>
    {
        return await axios.post(`subscription/cancelFreeSubscription/` + licenseId);
    }),

    manage: createAsyncThunk<LinkOutputDto, ManageSubscriptionDto>('subscription/startTrial', async (input: ManageSubscriptionDto) =>
    {
        return await axios.post<LinkOutputDto>(`subscription/manage`, input).then(res => res.data);
    }),
}