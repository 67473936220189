import {ErrorMessage, Field, Form, Formik, FormikHelpers, useFormikContext} from "formik";
import {PageTitle} from "Layouts/Default"
import * as Yup from 'yup'

import useLoadingProgress from "Hooks/useLoadingProgress";

import {IconCamera} from "Icons";
import {ChangeEvent, createRef} from "react";
import {useNavigate} from "react-router-dom";
import {upload} from "Services/OrganizationThumbnail.service";
import organizationsRemote from "Store/async/organizations.remote";

import {useAppDispatch, useAppSelector} from "Store/hooks";
import {organizationActions} from "Store/organizations.slice";
import {selectActiveOrg} from "Store/selectors"

import {FormInputRowContainer, InputThumbnailStyles, Styles} from "./styles";
import DrawLeaveOrgButton from "./DrawLeaveOrgButton";
import DrawDeleteOrgButton from "./DrawDeleteOrgButton";

export enum OrgEditMode {
	editCurrent,
	createNew
}

interface OrgEditionPageProps {
	mode: OrgEditMode,
	canEdit?: boolean;
}

interface EditFormProps {
	name: string
	description: string
	thumbnailUrl?: string
}

const OrgFormSchema = Yup.object().shape({
	name: Yup.string()
		.label('Organization Name')
		.min(4)
		.max(20)
		.required(),
	description: Yup.string()
})

export default function OrgEditionPage({mode, canEdit = true}: OrgEditionPageProps) {
	const navigate = useNavigate();
	const dispatch = useAppDispatch()
	const progress = useLoadingProgress();
	const activeOrg = useAppSelector(selectActiveOrg)

	function submitOrgEdit(values: EditFormProps, formikHelpers: FormikHelpers<EditFormProps>) {
		if (!activeOrg)
			return

		// Update organization details
		progress.start()
		dispatch(organizationsRemote.update({
			id: activeOrg!.id,
			name: values.name.trim(),
			description: values.description,
		}))
			.finally(progress.stop)

		// Update organization thumbnail
		if (values.thumbnailUrl) {
			progress.start()
			fetch(values.thumbnailUrl)
				.then(res => res.blob())
				.then(blob => upload(activeOrg.id, blob))
				.then(() => dispatch(organizationActions.setThumbnail(values.thumbnailUrl!)))
				.catch(e => console.error(e))
				.finally(progress.stop)
		}
	}

	async function submitOrgCreate(values: EditFormProps, formikHelpers: FormikHelpers<EditFormProps>) {
		try {
			// We can't upload a organization thumbnail if it does not exists...
			// Upload new organization details first and wait for the results
			progress.start()
			const newOrgResult = await dispatch(organizationsRemote.create({
				name: values.name.trim(),
				description: values.description
			})).unwrap()

			dispatch(organizationActions.setActiveOrganization(newOrgResult.id))

			// Upload organization thumbnail
			if (!values.thumbnailUrl) {
				dispatch(organizationActions.setThumbnail('https://via.placeholder.com/200'))
			}
			else {
				await fetch(values.thumbnailUrl)
					.then(res => res.blob())
					.then(blob => upload(newOrgResult.id, blob))
					.then(() => dispatch(organizationActions.setThumbnail(values.thumbnailUrl!)))
			}

			navigate('/dashboard')
		} catch (e) {
			console.error(e)
		} finally {
			progress.stop()
		}
	}

	function handleFormikSubmit(values: EditFormProps, formikHelpers: FormikHelpers<EditFormProps>) {
		switch (mode) {
			case OrgEditMode.editCurrent:
				return submitOrgEdit(values, formikHelpers)
			case OrgEditMode.createNew:
				return submitOrgCreate(values, formikHelpers)
		}
	}

	function getInitialValues(): EditFormProps {
		switch (mode) {
			case OrgEditMode.editCurrent:
				return {
					name: activeOrg?.name ?? '',
					description: activeOrg?.description ?? '',
					thumbnailUrl: activeOrg?.thumbnailUrl
				}
			case OrgEditMode.createNew:
				return {
					name: '',
					description: '',
				}
		}
	}

	return (
		// <DefaultPageTemplate
		// 	title={mode === OrgEditMode.editCurrent ? 'Edition' : 'Creation'}
		// 	ignoreNoOrgWarning={mode == OrgEditMode.createNew}
		// >

		<>
			<PageTitle>
				{mode === OrgEditMode.editCurrent ? 'Organization' : 'Creation'}
			</PageTitle>

			<Styles>

				{
					mode === OrgEditMode.editCurrent && (
						<DrawCurrentOrgInfo/>
					)
				}

				<div className="bottom-section">
					<Formik
						enableReinitialize={true}
						initialValues={getInitialValues()}
						onSubmit={handleFormikSubmit}
						validationSchema={OrgFormSchema}
					>
						<Form className='bottom-section--content'>
							<FormInputRowContainer className="org-input-row">

								{/*   PREVIEW   */}
								<DrawInputThumbnail className="org-img" canEdit={canEdit}/>

								{/*   NAME   */}
								<label className="org-name">
									<ErrorMessage name="name" component="p" className="error-message"/>
									<span className="c-xr" style={{marginRight: '0.5em'}}>Organization Name:</span>
									<Field disabled={!canEdit} name="name" type="text"/>
								</label>

								{/*   DESCRIPTION   */}
								<label className="org-desc">
									<p className="c-xr">Description:</p>
									<Field disabled={!canEdit} name="description" component="textarea" style={{resize: 'none'}}/>
								</label>

							</FormInputRowContainer>

							<div className="action-buttons">
								{
									canEdit && (
										<>
											<button type="submit">Save</button>
											<DrawResetFormButton mode={mode}/>
											<DrawDeleteOrgButton mode={mode}/>
										</>
									)
								}

								{ !canEdit && (<DrawLeaveOrgButton/>) }
							</div>

						</Form>
					</Formik>
				</div>
			</Styles>
		</>
	)
}

function DrawResetFormButton(props: { mode: OrgEditMode }) {
	const navigate = useNavigate();
	const formikContext = useFormikContext();

	function onClick() {
		switch (props.mode) {
			case OrgEditMode.createNew:
				return navigate(-1);
			case OrgEditMode.editCurrent:
				return formikContext.resetForm()
		}
	}

	return (
		<button type="button" onClick={onClick}> Cancel </button>
	)
}

function DrawCurrentOrgInfo() {
	const activeOrg = useAppSelector(selectActiveOrg)

	return (
		<div className="upper-section">
			<p className="t-center">Organization ID</p>
			<br/>
			<h2 className="t-center"> {activeOrg?.id} </h2>
		</div>
	)
}

function DrawInputThumbnail(props: { className: string, canEdit: boolean }) {

	const {values, setFieldValue} = useFormikContext<EditFormProps>();
	const fileInputRef = createRef<HTMLInputElement>()

	const showFilePicker = () => fileInputRef.current?.click()

	function onFileChange(e: ChangeEvent<HTMLInputElement>) {
		if (!e.target.files || e.target.files.length === 0)
			return

		setFieldValue('thumbnailUrl', URL.createObjectURL(e.target.files[0]))
	}

	return (
		<InputThumbnailStyles className={props.className}>
			<input type="file" accept="image/png" onChange={onFileChange} ref={fileInputRef}/>
			<img alt="file image preview" src={values.thumbnailUrl ?? "https://via.placeholder.com/200"}
			     onClick={showFilePicker}/>
			<IconCamera data-disabled={!props.canEdit} className="fill-xr" onClick={showFilePicker}/>
		</InputThumbnailStyles>
	)
}