import axios, {AxiosResponse} from "axios"

export default async function downloadBlobWithAuth(fileUrl: string, alternativeFileName?: string) {

	try {
		const response = await axios.get<Blob>(fileUrl, {responseType: "blob"})

		const blobUrl = URL.createObjectURL(response.data)

		const anchor = document.createElement('a')
		anchor.href = blobUrl
		anchor.setAttribute('download', getFileName(response) ?? alternativeFileName ?? 'file')
		anchor.click()

		URL.revokeObjectURL(blobUrl)
	} catch (e) {
		console.error(e)
	}
}

function getFileName(response: AxiosResponse) {
	try {
		const responseHeader = response.request.getResponseHeader('Content-Disposition')

		if (responseHeader) {
			return responseHeader.match(/filename=(.*);/)[1]
		}
	} catch (e) {
		console.error(e)
		return undefined
	}
}