import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import useWarningPopup from "../../Hooks/useWarningPopup.hook";
import useLoadingProgress from "../../Hooks/useLoadingProgress";
import {organizationSelector, selectActiveOrg} from "../../Store/selectors";
import organizationsRemote from "../../Store/async/organizations.remote";
import {organizationActions} from "../../Store/organizations.slice";
import {OrgEditMode} from "./index";

export default function DrawDeleteOrgButton(props: { mode: OrgEditMode }) {

	const dispatch = useAppDispatch();
	const warningPopup = useWarningPopup();
	const progress = useLoadingProgress();

	const activeOrg = useAppSelector(selectActiveOrg)
	const othersOrgs = useAppSelector(organizationSelector.selectAll)

	function showDeletePopup() {
		if (!activeOrg)
			return

		const {name: orgName, id: orgId} = activeOrg

		warningPopup.open({
			message: (
				<>
					You are about to delete this organization.
					<br/>
					Are you sure?
				</>
			),
			challenge: {
				title: (
					<>
						Type the organization name <span className='c-xr'>{orgName}</span> to confirm this action:
					</>
				),
				phrase: orgName
			},
			async onSuccess() {
				try {
					progress.start()

					// Delete this organization
					await dispatch(organizationsRemote.delete({
						id: orgId,
						orgNameQuestion: orgName
					}))

					// Set any other org as the "active"
					const organization = othersOrgs[0];
					dispatch(organizationActions.setActiveOrganization(organization.id))
				} catch (e) {
					console.error(e)
				} finally {
					progress.stop()
				}
			}
		})
	}

	// No need to display delete button while creating a new org
	if (props.mode == OrgEditMode.createNew)
		return null

	// Do not let user delete this organization if it's the only one left
	if (othersOrgs.length <= 1) {
		return null;
	}

	return (
		<button type="button" style={{backgroundColor: 'var(--red0)'}} onClick={showDeletePopup}>
			Delete
		</button>
	)
}