import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Plan } from "Models";
import plansRemote from "Store/async/plans.remote";
import createGuid from "Utilities/createGuid";

export function generateEmptyPlan(name: string) {
	return {
		id: createGuid(),
		name: name,
		editorIsNodeLocked: false,
		editorCount: 1,
		playerCount: 1,
		audioMinutes: 100,
		videoMinutes: 100,
		features : 0,
		supportLevel: 1,
		storageSize: 2 * (1024 * 1024 * 1024), // 2 GBs
	}
}

export const planAdapter = createEntityAdapter<Plan>({
	selectId: model => model.id
})

export const plansSlice = createSlice({
	name: 'plans',
	initialState: planAdapter.getInitialState(),
	reducers: {},
	extraReducers: builder => builder
		.addCase(plansRemote.list.fulfilled, (state, action) => {
			planAdapter.removeAll(state)
			planAdapter.addMany(state, action.payload)
		})
		.addCase(plansRemote.create.fulfilled, (state, action) => {
			if (action.payload) {
				planAdapter.addOne(state, action.payload)
			}
		})
		.addCase(plansRemote.delete.fulfilled, (state, action) => {
			planAdapter.removeOne(state, action.meta.arg.id)
		})
		.addCase(plansRemote.update.fulfilled, (state, action) => {

			const {id, ...changes} = action.meta.arg

			planAdapter.updateOne(state, {
				id, changes
			})
			// planAdapter.setOne(state, action.meta.arg)
		})
})