import styled from "styled-components";

export const Styles = styled.div`
	
  table input.feature {
    border: none;
    -moz-appearance: textfield;
    text-align: center;

    width: 8ch;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
	
	.v-space {
		margin: 0.5rem 0;
		display: inline-block;
	}
	
	.plan-info {
		display: flex;
		justify-content: space-between;
	}
	
	.flags {
		display: flex;
		
		b {	margin-right: 1rem;	}
    input { width: 100%; }
		
		.input-container {
			flex-grow: 1;
			width: 100%;

      position: relative;


      &.with-focus .flag-items{
        display: none;

        span {
          outline: none;
          border: 0;
          margin: 0;
          padding: 0;
        }
      }

      &.without-focus input{
        color: transparent;

        :not(:hover) {
          background-color: transparent;
          outline: none;
          border: none;
        }
      }
		}
		
		.flag-items {
			margin-top: -1.30rem;
			pointer-events: none;

      span {
        display: inline-block;

        background-color: var(--dark1);
        margin-right: 0.25rem;
	      margin-bottom: 0.5rem;

        padding: 0 0.5rem;
        border-radius: 0.5rem;
        outline: 1px solid var(--xr-dark);	      
      }
		}
	}

  .action-buttons {
    display: flex;
    justify-content: center;
    gap: 2rem;
	  margin-bottom: 1rem;
  }
`