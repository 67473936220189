import Table from "Components/Table";
import {PageContent, PageTitle} from "Layouts/Default"
import {User} from "Models";
import {orgUsersSelector} from "Store/selectors"
import {UsersPageStyles} from "./UsersPage.styles";
import {useState} from "react";
import {useAppSelector} from "Store/hooks";
import UsersTableRow from "./UsersTableRow";
import {ViewModeProps} from "../../routes";

const emptyUser = (): User => ({
	id: '',
	displayName: '',
	isActive: true,
	email: '',
	isAdmin: false,
	permissions: 0
});

export default function UsersPage(props: ViewModeProps)
{
	const [filterUsers, setFilterUsers] = useState('')

	const users = useAppSelector(orgUsersSelector.selectAll)

	return (
		<>
			<PageTitle> Users </PageTitle>
			<PageContent>
				<UsersPageStyles>
					<h2 className="t-center">Manage Users Account</h2>

					<div className="search-field">
						<input type="search" onChange={e => setFilterUsers(e.target.value.toLowerCase())}/>
					</div>

					<Table>
						<thead>
						<tr>
							<th className="c-xr">Name</th>
							<th className="c-xr">Email</th>
							<th className="c-xr">Permission</th>
							<th className="c-xr">Status</th>
						</tr>
						</thead>

						<tbody>
						{/* Display a single line to allow new users to be created */}

						{
							props.edit && (<UsersTableRow user={emptyUser()} displayMode={"create"} canEdit={props.edit}/>)
						}

						{
							users
								.filter(u => u.displayName.toLowerCase().includes(filterUsers) || u.email.includes(filterUsers))
								.map(u => (
									<UsersTableRow key={u.id + u.permissions + u.isActive} user={u} displayMode={'edit'} canEdit={props.edit}/>
								))
						}

						</tbody>
					</Table>

				</UsersPageStyles>
			</PageContent>
		</>
	)
}