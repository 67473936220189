import { useAppDispatch, useAppSelector } from "Store/hooks";
import { globalLoadingActions } from "Store/loadingProgress.slice";

export default function useLoadingProgress() {

	const dispatch = useAppDispatch();

	const isLoading = useAppSelector(s => s.globalLoading.isLoading);
	const setIsLoading = (state: boolean) => dispatch(globalLoadingActions.setLoading(state))

	const begin = () => setIsLoading(true)
	const stop = () => setIsLoading(false)

	return {isLoading, setIsLoading, start: begin, stop: stop}
}