import useDashboardStats from "Services/DashboardStats.service";
import CardBase, { CardCallback } from "./CardBase";
import React from "react";
import styled from "styled-components";
import { ReactComponent as IconStorage } from 'Assets/icons/CloudStorage_ICON.svg'

const Style = styled.div`
	span {
		display: inline-block;
	}
	
	.value {
		font-size: 1.5em;
	}

	.value-label {	
		margin-left: 1em;
		font-size: 0.75em;
	}
`

export default function StorageCard(props: CardCallback) {

	const {fileCount, maxSizeBytes, sizeBytes, usagePercent} = useDashboardStats().storage;

	const toGb = (size: number) => size / Math.pow(1024, 3);

	return (
		<CardBase {...props} label="Storage" imageSvg={<IconStorage />}>
			<Style>

				<p className="c-xr t-right f-small">{ fileCount } files</p>

				<p className="t-right">
					<span className="f-big"> { usagePercent } </span>
					<span className="f-normal">%</span>
				</p>

				<p className="c-xr t-right f-small">
					{toGb(sizeBytes).toFixed(2)} GB / { toGb(maxSizeBytes).toFixed(2) } GB
				</p>

			</Style>
		</CardBase>
	)
}