import styled from "styled-components";
import {IconAppStore, IconPlayStore, IconWindows, IconQuest} from "../../Icons";

interface DownloadItemProps
{
    icon: string;
    downloadText: string;
    platformText: string;
    url: string;
}

const Container = styled.div`
  margin-top: 2rem;
  .cards-container {
    display: grid;
    grid-gap: 1rem;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(80px, 200px));

    align-items: center;
    justify-content: center;

    width: 100%;
    gap: 1rem;
    //width: fit-content;
  }

  a {
    text-decoration: none;
    //background-color: hsla(0,0%,100%,.1);
    background-color: var(--xr-dark);
    border-radius: 5px;
    //display: grid;
    align-items: center;
    justify-items: center;
    display: flex;
    flex-direction: row;
    //grid-template-columns: repeat(2, 50%);
    gap: 0.5rem;
    padding: 0.5rem 2rem;
    width: fit-content;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100px;
  }
  
  h3 {
    text-align: center;
    margin-bottom: 0.5rem;
  }
  
  .download 
  {
    font-size: 0.7rem;
  }
  
  .platform
  {
    font-size: 1.18rem;
  }
`

export default function DownloadsContainer()
{
    return <Container>
        {/*<h3>Download here</h3>*/}
        <div className={"cards-container"}>
            <DownloadItem icon={IconWindows} downloadText={"Download for"} platformText={"Windows"}
                          url={"https://services.xrproj.com/api/v1/Installer/Download"}/>
            <DownloadItem icon={IconAppStore} downloadText={"Download on the"} platformText={"App Store"}
                          url={"https://apps.apple.com/us/app/xrproj/id1496967028"}/>
            <DownloadItem icon={IconPlayStore} downloadText={"Get it on"} platformText={"Play Store"}
                          url={"https://play.google.com/store/apps/details?id=com.formulaxr.xrproj"}/>
            <DownloadItem icon={IconQuest} downloadText={"Download for"} platformText={"Meta Quest"}
                          url={"https://www.oculus.com/experiences/quest/5572974999477126"}/>
        </div>
    </Container>
}

export function DownloadItem(props: DownloadItemProps)
{
    return (
        <a href={props.url} target={"_blank"}>
            <img src={props.icon} alt={""}></img>
            <div className={"description"}>
				<span className={"download"}>
					{props.downloadText}
				</span>
                <span className={"platform"}>
					{props.platformText}
				</span>
            </div>
        </a>);
}