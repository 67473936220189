import React from "react";
import styled from "styled-components";

import useDashboardStats from "Services/DashboardStats.service";
import CardBase, { CardCallback } from "./CardBase";

import {ReactComponent as IconEditor} from "Assets/icons/PlatformXR_ICON.svg";

const Styles = styled.div`
	div {
		display: grid;
		grid-template-columns: 1fr 1fr;
		
		span:last-child {
			text-align: right;
		}
	}
	
	.usage {
		text-align: center;
		margin-bottom: 0.25rem;
		
		::after{
			content: 'Min';
			font-size: 0.75rem;
		}
	}
`

export default function EditorCard(props: CardCallback) {

	const {editor, viewer, totalUsageTime} = useDashboardStats().xrproj;

	return (
		<CardBase {...props} label="XRProj" imageSvg={<IconEditor/>}>
			<Styles>

				<p className='usage f-normal'>{ totalUsageTime.toFixed() }</p>

				<div>
					<span className='c-xr'> Editor </span>
					<span> { editor } </span>
				</div>

				<div>
					<span className='c-xr'> Viewer </span>
					<span> { viewer } </span>
				</div>
			</Styles>
		</CardBase>
	)
}