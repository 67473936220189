import {PricingCardProps} from "Components/PricingTable/types"
import styled from "styled-components"

const Container = styled.div`

  height: 100%;

  padding: 34px 36px 28px;
  border-radius: 12px;

  border: 1px solid hsla(0, 0%, 100%, .2);
  background-color: hsla(0, 0%, 100%, .1);
  
  .pricing-description
  {
    color: var(--xr-light);
    font-size: 12px;
    margin-bottom: 20px;
    height: 30px;
  }

  .flex-container {
    display: flex;
    align-items: center;
  }

  .pricing-name {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .pricing-value {
    font-size: 36px;
    font-weight: 700;
  }

  .pricing-interval {
    margin: 17px 0 0 6px;
    opacity: 0.7;
    font-size: 13px;
    width: 100%;
  }

  button {
    height: 44px;
    width: 100%;
    border-radius: 6px;
    font-size: 16px;
    padding: 12px 24px;
    line-height: 1.15;

    transition: background-color .1s ease-in, transform .08s ease-in;

    :disabled {
      background-color: grey;
    }
  }

  .feature_list-label {
    margin: 12px 0;
    font-size: 14px;
  }

  .feature_list-items {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;

    list-style: none;

    svg {
      height: 12px;
      margin-right: 12px;
    }

    li {
      line-height: 1.2;
      font-size: 14px;
    }
  }
`

const CheckIcon = () => (
    <svg
        focusable="false" fill="#ffffff" color="#ffffff" fillOpacity="0.7" height="16" viewBox="0 0 16 16" width="16">
        <path
            d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
            fillRule="evenodd">
        </path>
    </svg>
)

export default function PricingCard(props: PricingCardProps)
{
    return (
        <Container>
            <p className="pricing-name"> {props.title} </p>
            <p className="pricing-description">{props.description}</p>

            <div className="flex-container" style={{marginBottom: '20px'}}>
                {
                    props.hasPrice ?
                        <>
                            <span className="pricing-value"> {props.price}</span>
                            <span className="pricing-interval">{"seat / month" }</span>
                        </> :
                        <span className="pricing-value"
                              style={{width: '100%', textAlign: 'center'}}>{props.price}</span>
                }

            </div>

            <button disabled={(props.disableBuyButton && !props.isEnterprise)}
                    onClick={() => props.onClickBuy?.()}>{props.buyText}</button>

            <p className="feature_list-label">{props.featureListText}:</p>
            <ul className="feature_list-items">

                {
                    props.featureList.map((featureName, index) => (
                        <li key={featureName}>
                            <CheckIcon/>
                            <span> {featureName} </span>
                        </li>
                    ))
                }
            </ul>
        </Container>
    )
}