import { useEffect } from "react"

export default function PageTitle(props: { children: string }) {

	useEffect(() => {
		document.title = `Portal XRProj | ${props.children}`
	}, [])

	return (
		<h1 style={{fontSize: '3rem', marginLeft: '2rem'}}>
			{props.children}
		</h1>
	)
}