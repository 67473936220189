import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  // I can't make the table grow vertically without stretching the rows
  // neither apply a margin on it... tables are weird
  .table-wrapper {
    height: 100%;
    background-color: #3f3f3f;

    table {
      width: 100%;
      border-collapse: collapse;

      background-color: #3f3f3f;
	    
	    th { color: var(--xr-light) }
      td { text-align: center; }
	    tr { line-height: 1.75em; }
      thead tr { border-bottom: 4px solid #4d4d4d; }
      tbody tr { border-bottom: 2px solid #393939; }
    }
  }
`

export interface ITableProps extends React.HTMLAttributes<HTMLDivElement> {
}

export default function Table(props: ITableProps) {

	const {children, ...rest} = props

	return (
		<Wrapper {...rest}>
			<div className="table-wrapper">
				<table>
					{ children }
				</table>
			</div>
		</Wrapper>
	)
}