import {useIsAuthenticated, useMsal} from "@azure/msal-react"
import PricingTable, {FetchDataResponse} from "Components/PricingTable"
import {useEffect, useState} from "react";
import axios, {AxiosResponse} from "axios";
import {PricingCardProps} from "Components/PricingTable/types";
import Enumerable from 'linq'
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "Store/hooks";
import {selectActiveOrg, selectActiveOrgLicense, selectIsAuthDataReady} from "Store/selectors";
import {loginRequest} from "authConfig";
import useLoadingProgress from "Hooks/useLoadingProgress";
import DownloadsContainer from "../../Components/DownloadsContainer";
import {StyledContent, StyledHeader} from "./styles"
import SubscriptionRemote, {LinkOutputDto, RequestSubscribeData} from "../../Store/async/subscription.remote";


export default function PricingPage()
{
    const [cardArgs, setCardArgs] = useState<FetchDataResponse>({})
    const msal = useMsal();
    const location = useLocation();
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const activeOrg = useAppSelector(selectActiveOrg);
    const isAuthDataReady = useAppSelector(selectIsAuthDataReady);
    const activeOrgLicense = useAppSelector(selectActiveOrgLicense);
    const progress = useLoadingProgress();
    const dispatch = useAppDispatch();

    useEffect(() =>
    {
        const searchParams = new URLSearchParams(location.search);
        if (!isAuthenticated || !isAuthDataReady || !searchParams.get('afterLogin'))
        {
            return
        }

        const priceId = sessionStorage.getItem('login_price_id')
        const isFree = sessionStorage.getItem('login_is_free') == 'true';

        // User already got a license, ignore this
        if (activeOrgLicense)
        {
            navigate("/dashboard")
        } else if (priceId)
        {
            if (isFree)
                startFreeSubscription(priceId)
            else
                startTrial(priceId);

            sessionStorage.removeItem("login_price_id")
            sessionStorage.removeItem("login_is_free")
        }
    }, [isAuthDataReady, isAuthenticated, location])

    function startTrial(priceId: string)
    {
        const data: RequestSubscribeData = {
            priceId,
            organizationId: activeOrg?.id,
            returnUrl: document.location.origin
        }

        progress.start()
        dispatch(SubscriptionRemote.startTrial(data))
            .then(response =>
            {
                const link = response.payload as LinkOutputDto;
                if (link)
                    document.location.assign(link.url)
            })
            .finally(() =>
            {
                progress.stop();
            })
    }

    function startFreeSubscription(priceId: string)
    {
        const data: RequestSubscribeData = {
            priceId,
            organizationId: activeOrg?.id,
            returnUrl: document.location.origin
        }

        progress.start()
        dispatch(SubscriptionRemote.subscribeFree(data))
            .then((response) =>
            {
                //@ts-ignore
                if (!response.error)
                    document.location.assign(document.location.origin)
            })
            .finally(() =>
            {
                progress.stop();
            })
    }

    function clickBuyHandler(priceId: string, isFree: boolean)
    {
        // User is authenticated ->
        // - fetch subscribe URL and redirect him to it

        // User is NOT authenticated ->
        // - save which priceId user has selected
        // - request login
        // - redirect user to payment

        if (priceId === "enterprise")
        {
            window.location.href = 'https://www.xrproj.com/index.php/contact/purchase';
            return;
        }

        // User needs to authenticate first
        if (msal.accounts.length == 0)
        {
            progress.start()
            msal.instance.loginRedirect({
                scopes: loginRequest.scopes,
                redirectStartPage: `/pricing?afterLogin=1`
            })
                .finally(() => progress.stop())

            sessionStorage.setItem("login_price_id", priceId)
            sessionStorage.setItem("login_is_free", isFree ? "true" : "false");
        } else
        {
            if (isFree)
                startFreeSubscription(priceId)
            else
                startTrial(priceId)
        }
    }

    useEffect(() =>
    {
        progress.start()
        fetchProducts()
            .then(prod => setCardArgs(prod))
            .finally(() => progress.stop())
    }, [])

    return (
        <>
            <StyledHeader>
                <h1>Pricing & Plans</h1>
                <span>that fit your needs and your budget</span>
            </StyledHeader>
            <StyledContent>

                {/*<h2>The easy way to create metaverse training, presentations or virtual manuals</h2>*/}
                <PricingTable onUserClickBuy={clickBuyHandler} cardArgs={cardArgs}
                              disableBuyButton={!!activeOrgLicense}/>

                <p className={"contact-us"}>For inquiries regarding perpetual license models, volume discounts, SSO,
                    dedicated servers, LMS integration, or real-time support <a
                        href={"https://xrproj.com/index.php/contact/purchase"}>Contact us</a></p>

                <DownloadsContainer/>

                <p className={"agreement"}>By downloading/subscribing, you agree to our <a
                    href={"https://docs.xrproj.com/legal/terms_of_use"}>Terms of Use</a></p>
                
            </StyledContent>
        </>
    )
}

function getEnterprisePrice(interval: "month" | "year"): ProductPrice
{
    return {
        currency: "usd",
        id: "enterprise",
        interval: interval
    }
}

function getProducts(response: AxiosResponse<ProductDto[]>)
{
    const products = response.data;

    const enterpriseProduct: ProductDto = {
        id: "enterprise",
        name: "Enterprise",
        description: "For enterprise companies, allows full integration with the corporate IT",
        features: ["Perpetual license models", "Volume discount", "SSO", "Dedicated servers", "LMS integration", "Realtime support"],
        prices: [getEnterprisePrice("month"), getEnterprisePrice("year")],
        isEnterprise: true
    }

    // products.push(enterpriseProduct);

    return Enumerable
        .from(products);
}

async function fetchProducts()
{
    return axios.get<ProductDto[]>("subscription/products")
        .then(response => getProducts(response)
            .selectMany(product => mapper(product))
            // .orderByDescending(pCardProps => parseInt(pCardProps.price))
            .groupBy(pCardProps => pCardProps.intervalName, pCardProps => pCardProps)
            .toObject(element => element.key(), element => element.toArray()))
}

// Use undefined for locale to retrieve it automatically
const moneyFormatter = new Intl.NumberFormat(["En-us"], {
    style: 'currency',
    currency: 'USD'
})

function mapper(product: ProductDto): PricingCardProps[]
{
    function getCardProps(price: ProductPrice)
    {
        function getPriceText()
        {
            if (price.unitAmount === 0)
                return "Free";

            if (!price.unitAmount)
                return "Custom price";

            let priceValue = price.unitAmount / 100;

            if (price.interval == "year")
                priceValue /= 12;

            return moneyFormatter.format(priceValue);
        }

        function getBuyText()
        {
            if (price.unitAmount === 0)
                return "Get started"

            return !price.unitAmount ? "Contact us" : "Start trial";
        }

        return ({
            title: product.name,
            description: product.description,
            price: getPriceText(),
            priceId: price.id,
            intervalName: price.interval.toString(),
            featureListText: price.unitAmount !== undefined ? "This includes" : "For inquiries regarding",
            featureList: product.features,
            buyText: getBuyText(),
            isEnterprise: product.isEnterprise,
            hasPrice: !!price.unitAmount,

        }) as PricingCardProps;
    }

    return product.prices.map(getCardProps);
}

type BillingInterval = "month" | "year"

interface ProductPrice
{
    id: string,
    unitAmount?: number,
    currency: "usd",
    interval: BillingInterval
}

interface ProductDto
{
    id: string,
    name: string,
    description: string
    prices: ProductPrice[],
    features: string[],
    isEnterprise: boolean
}