import GenericUsageGraphPanel, {GraphDataSelectors} from "./GenericUsageGraphPanel"
import {Meeting} from "Store/currentOrgStats.slice"

export default function VideoUsageGraphPanel() {

	const selectors: GraphDataSelectors<Meeting> = {
		getItems: source => source.meetings.meetings,
		getItemDate: item => item.createdAt,
		getItemDuration: item => item.videoMinutes
	}

	return (
		<GenericUsageGraphPanel title='Video usage' selectors={selectors}/>
	)
}