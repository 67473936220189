import axios from "axios";

export async function upload(id: string, file: Blob) {
	const formData = new FormData();
	// Chromium-browsers does not get the original file name
	// if they come from a local blob URL (URL.createObjectURL)
	formData.append('file', file, "image.png")

	const res = await axios.post(`organizations/${ id }/thumbnail`, formData)
}

export async function download(id: string): Promise<Blob> {
	const res = await axios.get<Blob>(`organizations/${ id }/thumbnail`, {
		responseType: 'blob'
	})

	return res.data
}

export async function downloadTo(id: string, imageElement: HTMLImageElement) {
	try {
		const blob = await download(id);

		const url = URL.createObjectURL(blob)
		const onLoad = () => {
			URL.revokeObjectURL(url)
			imageElement.removeEventListener('load', onLoad)
		}

		imageElement.src = url
		imageElement.addEventListener('load', onLoad)
	}

	// CORS error is being thrown because the server is redirecting the request to 'https://ui-avatars.com'
	// for organizations without a thumbnail.
	catch (e) {
		imageElement.src = "https://via.placeholder.com/200"
		console.error(e)
	}
}