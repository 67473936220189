import { PageContent } from "Layouts/Default"
import styled from "styled-components";

export const Styles = styled(PageContent)`
  > div {
    background-color: #4d4d4d;
  }

  .upper-section {
    margin-bottom: 0.5rem;
    padding: 2rem;
    
    p {
      font-size: 1.5rem;
    }
    
    h2{
      font-size: 2rem;
    }
  }

  .bottom-section {
    padding: 1rem;

    .bottom-section--content {
      background-color: #3f3f3f;
      padding: 2rem 4rem;
    }
  }
  
  svg[data-disabled='true']
  {
    pointer-events: none;
    
    * {
      fill: grey;
    }
  }

  .action-buttons {
    display: flex;	  
    gap: 0.5rem;
	  flex-direction: row;
	  
	  align-items: center;
	  justify-content: center;

	  margin-top: 2rem;
	  
	  button { width: 10rem; }
  }
`

export const FormInputRowContainer = styled.div`
  display: grid;
  grid-template: 
		"img org-name" 2rem
		"img org-desc" auto
		/ min-content auto;

  // Applying grid areas
  .org-img {
    grid-area: img;
  }

  .org-name {
    grid-area: org-name;
  }

  .org-desc {
    grid-area: org-desc;
  }

  // Styling those areas
  .org-img {
    margin-right: 4rem;
  }

  .org-name {
    position: relative;
    display: flex;
    align-items: center;

    input {
      max-width: 50ch;
      font-size: 1rem;
      flex-grow: 1;
    }
	  
    .error-message {
      position: absolute;
      bottom: 100%;
      color: var(--red-custon);
      font-style: italic;
    }
  }

  .org-desc {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;

    textarea {
      margin-top: 0.5rem;
      flex-grow: 1;
      padding: 0.25rem 0.5rem;
    }
  }
`

export const InputThumbnailStyles = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 200px;
  width: 200px;

  outline: 4px solid var(--dark2);

	// Input holding value
  input { display: none; }

	// Item preview
  img	{
    max-width: 100%;
    max-height: 100%;
  }

	// Icon Camera
  svg {
    position: absolute;
    bottom: -1.5rem;
    right: -1.5rem;

    width: 4rem;

    background-color: var(--gray1-alt);
    border-radius: 100%;
  }
`