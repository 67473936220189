import { DateTime } from "luxon";
import { useAppSelector } from "Store/hooks";
import {orgUserAdapter} from "../Store/organizationUsers.slice";
import {useMemo} from "react";

export default function useDashboardStats() {

	const stats = useAppSelector(s => s.currentOrgStats)
	const users = useAppSelector(s => s.orgUsers)
	const license = useAppSelector(s => s.organizationLicense.current)

	return useMemo(() => {
		const totalUsersCount = orgUserAdapter.getSelectors().selectTotal(users);

		const video = {
			usage: stats?.meetings.videoMinutes ?? 0,
			max: license?.plan.videoMinutes ?? 0
		};

		const audio = {
			usage: stats?.meetings.audioMinutes ?? 0,
			max: license?.plan.audioMinutes ?? 0
		};

		const storage = {
			sizeBytes: stats?.storage.totalFileSize ?? 0,
			maxSizeBytes: license?.plan.storageSize ?? 0,
			fileCount: stats?.storage.totalFileCount ?? 0
		}

		return {
			video: {
				...video,
				usagePercent: computePercent(video.usage, video.max)
			},

			audio: {
				...audio,
				usagePercent: computePercent(audio.usage, audio.max)
			},

			storage: {
				...storage,
				usagePercent: computePercent(storage.sizeBytes, storage.maxSizeBytes)
			},

			license: {
				planName: license?.plan.name,
				expirationDateRaw: license?.expirationDate,
				expirationDate: DateTime.fromISO(license?.expirationDate ?? '').toFormat('dd/MM/yyyy')
			},

			users: {
				activationCount: stats?.activations.activeUserCount ?? 0,
				usersCount: totalUsersCount
			},

			xrproj: {
				totalUsageTime: stats?.activations.totalActivationTime ?? 0,
				editor: stats?.activations.editorActivationCount ?? 0,
				viewer: stats?.activations.playerActivationCount ?? 0
			}
		}
	}, [stats, license, users])
}

function computePercent(usage: number, max: number) {
	if (max <= -1)
		return 0;

	const result = (usage / max) * 100;

	if (Number.isNaN(result)) {
		return 0;
	}

	return result.toFixed()
}