import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Plan } from "Models";

export interface CreatePlanArgs {
	name: string
	editorCount: number
	playerCount: number
	audioMinutes: number
	videoMinutes: number
	storageSize: number
	features: number
}

export interface UpdatePlanArgs extends CreatePlanArgs {
	id: string
}

export interface DeletePlanArgs {
	id: string
}

export default {
	create: createAsyncThunk<Plan, CreatePlanArgs>('plans/create', async (plan) => {
		return axios.post<Plan>('plans', plan).then(res => res.data)
	}),

	list: createAsyncThunk<Plan[], void>('plans/list', async (arg) => {
		return axios
			.get<Plan[]>('plans')
			.then(res => res.data)
	}),

	update: createAsyncThunk<void, UpdatePlanArgs>('plans/update', async (plan) => {
		return axios.put(`plans/${ plan.id }`, plan).then(res => res.data)
	}),

	delete: createAsyncThunk<Plan, DeletePlanArgs>('plans/delete', async (plan) => {
		return axios.delete(`plans/${ plan.id }`).then(res => res.data)
	})
}