import {User} from "../../Models";
import {FormikHelpers} from "formik";
import useLoadingProgress from "../../Hooks/useLoadingProgress";
import {useAppDispatch} from "../../Store/hooks";
import OrgUsersRemote from "../../Store/async/orgUsers.remote";
import {DisplayMode} from "./UsersTableRow";

export async function saveChanges(user: User, orgId: string, displayMode: DisplayMode, helpers: {
    formik: FormikHelpers<User>
    progress: (ReturnType<typeof useLoadingProgress>),
    dispatch: (ReturnType<typeof useAppDispatch>)
    setIsEditing: (state: boolean) => void
})
{

    const {dispatch, formik, progress, setIsEditing} = helpers;

    progress.start()

    async function createUser()
    {
        const {displayName, email, permissions, isActive} = user
        await dispatch(OrgUsersRemote.create({orgId, displayName, email, permissions, isActive}))
            .then(() => formik.resetForm())
    }

    async function updateUser()
    {
        const {id, permissions, isActive} = user
        await dispatch(OrgUsersRemote.update({orgId, userId: id, permissions, isActive}))
    }

    try
    {
        switch (displayMode)
        {
            case "create":
            {
                await createUser();
                break
            }

            case "edit":
            {
                await updateUser();
                break
            }
        }
    } finally
    {
        progress.stop()
        setIsEditing(false)
        formik.setSubmitting(false)
    }
}