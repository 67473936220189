import {Permission} from "Models";

export interface ReducerActionType {
	type: 'clear' | 'toggle' | 'set'
	payload?: {
		toggleState: boolean,
		flag: number
	}
}

export function selectEnumFlagsReducer(state: number, action: ReducerActionType) {

	function addFlag(flag: number) {
		// User just selected a flag who is not "Default"
		if ((flag & Permission.Default) != Permission.Default)
			flag |= Permission.Default

		// "PortalView" must be selected together with "PortalManage"
		if ((flag & Permission.PortalManage) == Permission.PortalManage)
			flag |= Permission.PortalView

		return state | flag
	}

	function removeFlag(flag: number) {
		return state & ~flag;
	}

	switch (action.type) {
		case 'toggle': {
			if (!action.payload)
				return state

			const {toggleState, flag} = action.payload
			return toggleState ? addFlag(flag) : removeFlag(flag)
		}

		case 'clear':
			return 0;

		case "set":
			return action.payload!.flag

		default: return state;
	}
}