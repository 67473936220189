import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { IconCompanyLogo } from "Icons"
import OrgSelectorDropdownWithCreate from "Templates/Default/HeaderBar/OrgSelectorDropdownWithCreate"
import UserProfileImageAndLoginInfo from "Templates/Default/HeaderBar/UserProfileImageAndLoginInfo"
import UserViewModeSelector from "Templates/Default/HeaderBar/UserViewModeSelector"
import ProgressBar from "Templates/Default/HeaderBar/ProgressBarElement";
import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  position: relative;

  display: grid;
  grid-template:
	  "left middle right"
		/ min-content auto min-content;

  gap: 1rem;
  align-items: center;

  width: 100vw;
  height: 4rem;

  padding: 0 0.8rem;
  background-color: var(--dark2);

  .middle-container {
    grid-area: middle;
    justify-self: center;

    width: 100%;
    display: grid;
    grid-template: 
		  "mleft mmidle mright"
  		/ min-content auto min-content;

    align-items: center;
  }
`

/*
* I'm manually using a inline "justify-self" to emulate the behavior of "justify-content: space-between",
*  but this will balance the content's position with the new available space.
* I want them to stay on his grid-area without changing the position, even if the content is empty.
* */
export default function HeaderBar() {

	const location = useLocation();
	const isAuthenticated = useIsAuthenticated();

	return (
		<Container style={{gridArea: "header"}}>
			<div style={{gridArea: 'left', justifySelf: 'flex-start'}}>
				<IconCompanyLogo/>
			</div>

			<div className="middle-container">

				<div style={{gridArea: 'mleft', justifySelf: 'flex-start'}}>
					{/* Only display at PricingPage */}

					{
						location.pathname == '/pricing' && (
							// I had to add this margin to make it be aligned with the company logo
							<h1 style={{marginBottom: '8px'}}>STORE</h1>
						)
					}
				</div>

				<div style={{gridArea: 'mmidle', justifySelf: 'center'}}>
					{isAuthenticated && <OrgSelectorDropdownWithCreate/>}
				</div>

				<div style={{gridArea: 'mright', justifySelf: "flex-end"}}>
					{/* Space reserved for future usage */}
				</div>

			</div>

			<div style={{gridArea: 'right', justifySelf: 'flex-end'}}>
				<UserButton/>
			</div>

			<ProgressBar/>
		</Container>
	)
}

function UserButton() {
	const isAuthenticated = useIsAuthenticated();

	if (!isAuthenticated) {
		return <LoginButton/>
	}
	else {
		return (
			<div style={{display: 'flex'}}>
				<UserViewModeSelector/>
				<UserProfileImageAndLoginInfo/>
			</div>
		)
	}
}

function LoginButton() {
	const msal = useMsal()

	return (
		<button style={{whiteSpace: 'nowrap'}} onClick={() => msal.instance.loginRedirect()}>
			Login or Register
		</button>
	)
}