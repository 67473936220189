import GenericUsageGraphPanel, {GraphDataSelectors} from "./GenericUsageGraphPanel"
import {Meeting} from "Store/currentOrgStats.slice"

export default function AudioUsageGraphPanel() {

	const selectors: GraphDataSelectors<Meeting> = {
		getItems: source => source.meetings.meetings,
		getItemDate: item => item.createdAt,
		getItemDuration: item => item.duration
	}

	return (
		<GenericUsageGraphPanel title={"Audio usage"} selectors={selectors}/>
	)
}