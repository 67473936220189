export interface Installer {
	commitId: string;
	version: string;
	uploadDate: string;
	isActive: boolean;
}

export type Organization = {
	id: string,
	ownerUserId: string,
	name: string,
	description: string,
	permissions: Permission
	thumbnailUrl: string,
}

export interface User {
	id: string
	email: string
	displayName: string
	isAdmin: boolean,
	isActive: boolean,
	permissions: Permission
}

export enum Permission {
	None = 0,

	Default = 1 << 1,
	AppEditor = 1 << 2,
	PortalView = 1 << 3,
	PortalManage = 1 << 4,

	Everything = AppEditor | PortalView | PortalManage
}

export interface Plan {
	id: string
	name: string
	editorCount: number
	playerCount: number
	audioMinutes: number
	videoMinutes: number
	storageSize: number
	supportLevel: number
	features: number
}

export interface LockedDevice {
	licenseId: string
	deviceId: string
	description: string
}

export interface License {
	id: string
	plan: Plan
	groupId: string
	creationDate: string
	expirationDate: string
	seats: number

	isActive: boolean
	isSubscription : boolean,
	isFree : boolean
	subscriptionStatus : string
}

export interface LicenseWithLockedDevices extends License {
	lockedDevices: LockedDevice[]
}