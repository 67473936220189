import styled from "styled-components";

const CheckboxStyle = styled.label`
	position: relative;
	// Must be inline-block to centralize the content
	display: inline-block;
	
	width: 1rem;
	height: 1rem;
	
	cursor: pointer;

	input {
    position: absolute;
		// It's important to hide by opacity to kept keyboard navigation working fine
    opacity: 0;
    height: 0;
    width: 0;
	}
	
	span::before {
		position: absolute;
		content: '';
		
		top: 0;
		left: 0;
		
		width: 100%;
		height: 100%;
		
		background-color: black;
	}

  input:checked ~ span::after {
    position: absolute;
    content: '';

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: var(--xr-dark);
	  transform: scale(0.6);
  }

  // Firefox does not render the outline correctly on the container.
  // moving it to span::before works as expected
  :hover span::before,
  :focus-within span::before {
    outline: 1px solid var(--xr-dark);
  }
`

export interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> { }

export default function Checkbox(props: ICheckboxProps) {
	return (
		<CheckboxStyle>
			<input {...props} type="checkbox"/>
			<span/>
		</CheckboxStyle>
	)
}