import { Plan } from "Models";
import styled from "styled-components";
import PlanEditor, { IPlanEditorProps } from "./PlanEditor";
import { IconEdit, IconDelete } from "Icons";

const PlanStyles = styled.div`
  display: grid;
  grid-template-columns: auto min-content min-content;
  margin: 0.5rem auto;

  button {
    background-color: transparent;
    border: none;

    width: 1.5rem;
    cursor: pointer;
  }
`

export interface IPlanViewerProps {
	plan: Plan

	onDelete?: () => void
	onEdit?: () => void
}

export default function PlanViewer(props: IPlanViewerProps) {

	const { plan, onEdit, onDelete } = props

	return (
		<PlanStyles>
			<span> { plan.name } </span>
			<button onClick={ onEdit } className="default"><IconEdit className="fill-xr"/></button>
			<button onClick={ onDelete } className="default"><IconDelete className="fill-red"/></button>
		</PlanStyles>
	)
}