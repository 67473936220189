import React from "react"

interface Props {
	className?: string
	children?: React.ReactNode
}

export default function PageContent(props: Props) {
	return (
		<div style={{padding: '2rem 4rem'}} className={`${props.className ?? ''}`}>
			{props.children}
		</div>
	)
}