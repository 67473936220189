import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { stat } from "fs";

export interface Analytics {
	activations: ActivationsStats
	storage: StorageStats
	meetings: MeetingStats
}

export interface ActivationsStats {
	fromDate: string,
	toDate: string,

	totalActivationCount: number,
	editorActivationCount: number,
	playerActivationCount: number,

	activeUserCount: number,
	activeDeviceCount: number,

	totalActivationTime: number,
	totalEditorTime: number,
	totalPlayerTime: number,

	averageActivationTime: number,
	averageEditorTime: number,
	averagePlayerTime: number

	activations: Activation[]
}

export interface StorageStats {
	totalFileSize: number
	totalFileCount: number
}

export interface MeetingStats {
	licenseId: string
	fromDate: string
	toDate: string

	audioMinutes: number
	videoMinutes: number
	duration: number
	averageDuration: number
	averageUserCount: number

	meetings: Meeting[]
}

export interface Meeting {
	id: string
	fileId: string
	fileName: string
	audioMinutes: number
	videoMinutes: number
	duration: number
	userCount: number
	createdAt: string
}

export interface Activation {
	id: string
	licenseId: string
	deviceId: string
	deviceName: string
	userId: string
	startDate: string
	minutes: number
	isEditor: boolean
}

export const getOrgStats = createAsyncThunk<Analytics, string>('current-org-stats/get', ((arg, thunkAPI) => {
	return axios.get<Analytics>(`Licenses/${arg}/Analytics`).then(res => res.data)
}))

export const currentOrgStatsSlice = createSlice({
	name: 'current-org-stats',
	initialState: <Analytics | null>null,
	reducers: {},
	extraReducers: builder => builder
		.addCase(getOrgStats.fulfilled, (state, action) => {
			return action.payload
		})
})