import useLoadingProgress from "Hooks/useLoadingProgress"
import useOrgCount from "Hooks/useOrgCount"
import {useEffect} from "react"
import styled from "styled-components"

import HeaderBar from "Layouts/Default/HeaderBar"
import SideBar from "Layouts/Default/SideBar"

import {Outlet, useLocation, useNavigate} from "react-router-dom"

import PageTitle from "Layouts/Default/PageTitle"
import PermissionUtils from "Utilities/permissionUtils"
import PageContent from "./PageContent"
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {InteractionStatus} from "@azure/msal-browser";
import {useAppSelector} from "Store/hooks";
import {selectActiveOrg} from "Store/selectors";
import getToken from "../../Utilities/getToken";
import {msalInstance} from "../../index";

const Container = styled.main`
  // Force the page to use the whole vertical space
  min-height: 100vh;

  &[data-block-interaction="true"]::after {
    content: '';
    position: fixed;

    z-index: 9999;

    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.25);
  }
`

export default function DefaultLayout()
{
    const msal = useMsal()
    const navigate = useNavigate()
    const location = useLocation()
    const [isReady] = useOrgCount()
    const isAuthenticated = useIsAuthenticated()
    const loadingProgress = useLoadingProgress()
    const organization = useAppSelector(selectActiveOrg)

    useEffect(() =>
    {
        if (msal.inProgress == InteractionStatus.None && !isAuthenticated && location.pathname !== "/pricing")
        {
            msal.instance.loginRedirect().then(() =>
            {
            });
        } else if (isReady && !organization && location.pathname !== "/pricing")
        {
            navigate("/pricing", {replace: true})
        } 
        else if (isReady && organization && location.pathname === "/")
        {
            const path = PermissionUtils.hasView(organization.permissions) ? "/dashboard" : "/organization/edit";
            navigate(path, {replace: true});
        }

    }, [isReady, navigate, location, msal, organization])

    return (
        <Container data-block-interaction={loadingProgress.isLoading}>
            <HeaderBar/>

            <div style={{display: 'flex'}}>
                {isAuthenticated && <SideBar/>}

                <div style={{
                    padding: '2em 1em 1em 1em',
                    flexGrow: 1,
                    height: "calc(100vh - 4rem)",
                    overflowY: "scroll"
                }}>
                    <Outlet/>
                </div>
            </div>
        </Container>
    )
}

export {PageTitle, PageContent}