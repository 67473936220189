import styled from "styled-components";

export const Styles = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 400px;

  text-align: center;

  border-radius: 7pt;
  box-shadow: 3px 3px 8px 2px rgba(0,0,0,0.5);
  overflow: hidden;

  header {
    height: 100px;
    background-color: #d04b4c;

    svg {
      max-width: 100%;
      max-height: 100%;

      padding: 1rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    padding: 0.25rem 1rem 1rem;
    background-color: #323333;

    .secret-area {

      width: 100%;

      input {
        width: 100%;
        margin-top: 0.5rem;
        padding: 0 0.75rem;

        border: none;
        font-size: 1.2rem;

        &[data-valid="true"] { border: 1px solid forestgreen; }
        &[data-valid="false"] { border: 1px solid crimson; }
      }
    }

    .actions {
      display: flex;

      align-items: center;
      justify-content: center;
      gap: 2rem;

      button:disabled {
        cursor: not-allowed;
        pointer-events: none;
        background-color: grey !important;
      }
    }
  }
`