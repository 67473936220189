import { WarningPopupProps } from "Popup/WarningPopup";
import { createContext, useState } from "react";

export interface WarningPopupContextProps {
	isOpen: boolean
	close: () => void
	open: (arg: WarningPopupProps) => void

	currentWarn: WarningPopupProps
}

export const WarningPopupContext = createContext({} as WarningPopupContextProps);

export default function WarningPopupProvider (props: {children: any}){

	const [isOpen, setIsOpen] = useState(false)
	const [popupProps, setPopupProps] = useState({} as WarningPopupProps)

	const close = () => setIsOpen(false)
	const open = (popupProps: WarningPopupProps) => {
		setPopupProps(popupProps)
		setIsOpen(true)
	}

	return (
		<WarningPopupContext.Provider value={{isOpen, currentWarn: popupProps, open, close}}>
			{props.children}
		</WarningPopupContext.Provider>
	)
}