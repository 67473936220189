import GenericUsageGraphPanel, {GraphDataSelectors} from "./GenericUsageGraphPanel"
import {Activation} from "Store/currentOrgStats.slice"

export default function EditorUsageGraphPanel() {

	const selectors: GraphDataSelectors<Activation> = {
		getItems: source => source.activations.activations.filter(item => item.isEditor),
		getItemDate: item => item.startDate,
		getItemDuration: item => Math.max(item.minutes, 0)
	}

	return (
		<GenericUsageGraphPanel title={"Editor usage"} selectors={selectors}/>
	)
}