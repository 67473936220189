import { ReactComponent as IconDelete } from "Assets/icons/X_ICON.svg";
import { ReactComponent as IconEdit } from "Assets/icons/Edit_ICON.svg";
import { ReactComponent as IconUsers } from 'Assets/icons/Users_ICON.svg';
import { ReactComponent as IconCamera } from "Assets/icons/Photo_ICON.svg";
import { ReactComponent as IconSearch } from "Assets/icons/Search_ICON.svg";
import { ReactComponent as IconLicense } from 'Assets/icons/License_ICON.svg';
import { ReactComponent as IconDashboard } from 'Assets/icons/Dashboard_ICON.svg';
import { ReactComponent as IconEditPlans } from 'Assets/icons/EditPlans_ICON.svg';
import { ReactComponent as IconEditParameters } from 'Assets/icons/EditParameters_ICON.svg';
import { ReactComponent as IconInstaller } from 'Assets/icons/Installer_ICON.svg';
import { ReactComponent as IconCheck } from 'Assets/icons/Check_ICON.svg';
import { ReactComponent as IconWarning } from 'Assets/icons/Warning_ICON.svg';
import { ReactComponent as IconTrashBin } from 'Assets/icons/TrashBin.svg';
import iconLogo from "Assets/icons/XR_LOGO.svg"
import IconWindows from "Assets/icons/Windows_ICON.png"
import IconQuest from "Assets/icons/Quest_ICON.png"
import IconAppStore from "Assets/icons/AppStore_ICON.png"
import IconPlayStore from "Assets/icons/PlayStore_ICON.png"
import LoadingGif from "Assets/loading.gif"
import React from "react"
import styled from "styled-components";

const IconPlus = styled(IconDelete)`
	transform: rotateZ(45deg);
`

const IconInfinite = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>) => (
	<span {...props}> &infin; </span>
)

const IconCompanyLogo = styled.img.attrs({src: iconLogo, id: 'comp-logo'})`
	width: 2.5em;
	cursor: pointer;
`

export {
	IconEdit,
	IconPlus,
	IconCheck,
	IconUsers,
	IconDelete,
	IconCamera,
	IconSearch,
	IconLicense,
	IconWarning,
	IconInfinite,
	IconTrashBin,
	IconDashboard,
	IconEditPlans,
	IconInstaller,
	IconEditParameters,
	IconCompanyLogo,
	IconWindows,
	IconQuest,
	IconPlayStore,
	IconAppStore,
	LoadingGif
}