import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { Installer } from "Models";
import InstallersRemote from "Store/async/installers.remote";

const installerAdapter = createEntityAdapter<Installer>({
	selectId: model => model.version,
	sortComparer: (a, b) => {
		const dateA = DateTime.fromISO(a.uploadDate)
		const dateB = DateTime.fromISO(b.uploadDate)

		return dateA < dateB ? 1 : -1
	}
})

export const installersSlice = createSlice({
	name: 'instalers',
	initialState: installerAdapter.getInitialState(),
	reducers: {},
	extraReducers: builder => builder
		.addCase(InstallersRemote.list.fulfilled, (state, action) => {
			installerAdapter.removeAll(state)
			installerAdapter.addMany(state, action.payload)
		})
		.addCase(InstallersRemote.delete.fulfilled, (state, action) => {
			installerAdapter.removeOne(state, action.meta.arg)
		})
		.addCase(InstallersRemote.update.fulfilled, (state, action) => {
			const {isActive, version} = action.meta.arg;

			 installerAdapter.updateOne(state, {
				 id: version,
				 changes: { isActive }
			 })
		})
})