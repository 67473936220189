import useLoadingProgress from "Hooks/useLoadingProgress";
import {PageContent, PageTitle} from "Layouts/Default"
import {useState} from "react";
import styled from "styled-components";
import {useAppDispatch, useAppSelector} from "Store/hooks";


import {Plan} from "Models";
import PlanViewer from "./admin_parts/PlanViewer";
import PlanEditor, {PlanEditorPropsBase} from "Pages/admin_parts/PlanEditor";

import {generateEmptyPlan} from "Store/plans.slice";
import useWarningPopup from "Hooks/useWarningPopup.hook";

import plansRemote from "Store/async/plans.remote";

const Styles = styled.div`
  background-color: var(--gray0-alt);
  padding: 1rem;
	
	// I have no clue why 1.02em
	* { font-size: 1.02em; }
	
	.title {
		text-align: center;
		margin-bottom: 1.5rem;
		font-size: 1.5em;
	}

  .create-plan {
    width: 12ch;
    margin-left: calc(100% - 12ch);
  }
`

export default function PlansPage()
{

    const [editedPlan, setEditedPlan] = useState<PlanEditorPropsBase | null>(null)

    const stopEdition = () => setEditedPlan(null)
    const beginEdition = (p: Plan) => setEditedPlan({plan: p, mode: 'edit'})
    const createNewPlan = () => setEditedPlan({mode: 'create', plan: generateEmptyPlan("New plan")})

    return (
        <>
            <PageTitle> Plans </PageTitle>
            <PageContent>
                <Styles>
                    <p className="title">Plans</p>

                    {
                        // Only draw "Create plan" button while editor is closed
                        !editedPlan && <button className="create-plan" onClick={createNewPlan}>Create New</button>
                    }

                    {
                        !editedPlan
                            ? <DrawPlanList onEdit={beginEdition}/>
                            : <DrawPlanEditor editingPlan={editedPlan} onClose={stopEdition}/>
                    }
                </Styles>
            </PageContent>
        </>
    )
}

const DrawPlanListStyles = styled.div`
  margin-top: 0.5rem;
  padding: 0.1rem 1rem;

  background-color: var(--dark0);

	// I don't want to add a class name for the items, but this selector will work fine :)
  > * {
    position: relative;

    ::after {
      content: '';
      position: absolute;

      bottom: 0;
      width: 100%;
      height: 1px;

      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`

function DrawPlanList(props: { onEdit: (plan: Plan) => void })
{

    const popup = useWarningPopup();
    const dispatch = useAppDispatch();
    const progress = useLoadingProgress();
    const allPlans = useAppSelector(state => Object.values(state.plans.entities));

    const tryDeletePlan = (plan: Plan) =>
    {

        popup.open({
            message: 'Are you sure to delete this plan?',
            onSuccess: () =>
            {
                progress.start()
                dispatch(plansRemote.delete(plan))
                    .finally(progress.stop)
            }
        })
    }

    return (
        <DrawPlanListStyles>
            {
                allPlans.sort(p => p?.audioMinutes ?? 0).map(p => p && (
                    <PlanViewer
                        key={p.id} plan={p}
                        onEdit={() => props.onEdit(p)}
                        onDelete={() => tryDeletePlan(p)}
                    />
                ))
            }
        </DrawPlanListStyles>
    )
}

function DrawPlanEditor(props: { editingPlan: PlanEditorPropsBase, onClose: () => void })
{
    const p = props.editingPlan

    return (
        <PlanEditor
            plan={p.plan} mode={p.mode}
            onCancel={props.onClose}
        />
    )
}