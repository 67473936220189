import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import useLoadingProgress from "../../Hooks/useLoadingProgress";
import useWarningPopup from "../../Hooks/useWarningPopup.hook";
import {useContext} from "react";
import {useFormikContext} from "formik";
import {User} from "../../Models";
import OrgUsersRemote from "../../Store/async/orgUsers.remote";
import {IconCheck, IconDelete, IconEdit, IconPlus, IconTrashBin} from "../../Icons";
import {RowContext} from "./UsersTableRow";
import {ViewModeProps} from "../../routes";

export function DrawActionButtons() {
	const dispatch = useAppDispatch();
	const progress = useLoadingProgress();

	const warningPopup = useWarningPopup();
	const activeOrg = useAppSelector(s => s.organizations.current)

	const {user, displayMode, isEditing, setIsEditing} = useContext(RowContext)
	const {submitForm, resetForm} = useFormikContext<User>();

	const cancelEdition = () => {
		resetForm()
		setIsEditing(false)
	}

	function deleteCurrentUser() {
		warningPopup.open({
			message: (
				<>
					<p>You are about to delete {user.displayName}</p>
					<p>Are you sure?</p>
				</>
			),
			onSuccess: () => {
				progress.start()
				dispatch(OrgUsersRemote.delete({
					orgId: activeOrg!.id,
					userId: user.id
				}))
					.finally(progress.stop)
			}
		})
	}


	if (displayMode == "create") {
		return (
			<IconPlus style={{width: '25px'}} className="fill-xr pointer" onClick={submitForm}/>
		)
	}

	if(!isEditing) {
		return (
			<IconEdit style={{width: '25px'}} className="fill-xr pointer" onClick={() => setIsEditing(true)}/>
		)
	}

	return (
		<>
			<IconTrashBin title="Delete user" style={{width: '25px'}} className="pointer" onClick={deleteCurrentUser}/>
			<IconDelete title="Cancel edition" style={{width: '25px'}} className="pointer" onClick={cancelEdition}/>
			<IconCheck title="Save changes" style={{width: '25px'}} className="fill-green pointer" onClick={submitForm}/>
		</>
	)
}