import axios from "axios";
import {useEffect} from "react";
import useNotification from "Hooks/useNotification.hook";
import getToken from "Utilities/getToken"

export default function AxiosConfiguration() {
	const notification = useNotification();

	useEffect(() => {
		axios.defaults.baseURL = process.env.REACT_APP_API_URL;

		const idRequest = axios.interceptors.request.use(async config => {

			const token = await getToken()

			if (token) {
				config.headers = {
					authorization: `Bearer ${token}`
				}
			}
			return config
		})

		// Is this case, we only care about the errors, so we can display some notifications about it
		const idResponse = axios.interceptors.response.use(value => value, (error: any) => {
			let errorMessage = "Fail to process the request";
			const response = error.response;

			if (response) {
				const data = response.status >= 500 ? "InternalServerError" : response.data;
				errorMessage = "Error! Status: " + response.status + " - Message: " + data;
			}

			console.error(errorMessage)
			notification.add(errorMessage);

			return Promise.reject(error)
		})

		console.log("Axios default settings applied")

		return () => {
			axios.interceptors.request.eject(idRequest)
			axios.interceptors.response.eject(idResponse)
		}
	}, [])

	return null
}

interface ValidationError {
	title: string
	status: number
	errors: Record<string, string[]>
}