import {Permission} from "Models";
import React, {ChangeEvent, createRef, useEffect, useMemo, useReducer, useState} from "react";
import {Styles} from "./SelectEnumFlags.styles";
import {selectEnumFlagsReducer} from "./SelectEnumFlags.reducer";

export interface SelectEnumFlagsProps {
	isReadonly?:boolean

	value?: number
	onChange?: (flags: number) => void
}

export default function SelectEnumFlags(props: SelectEnumFlagsProps) {
	const rootRef = createRef<HTMLDivElement>()
	const [isOptionsVisible, setIsOptionsVisible] = useState(false)
	const [currentFlag, dispatch] = useReducer(selectEnumFlagsReducer, props.value ?? 0)

	// Compute a new label string after new option be selected
	const label: string = useMemo(
		() => computeLabelFromFlags(currentFlag),
		[currentFlag]
	)

	// Reset active flags after parent container asked for
	useEffect(() => {
		dispatch({
			type: 'set',
			payload: {
				flag: props.value!,
				toggleState: true
			}
		})
	}, [props.value])

	// Notify "property changed" after a option be selected
	useEffect(
		() => props.onChange?.(currentFlag),
		[currentFlag]
	)

	// Hide options if user clicks outside the element
	useEffect(() => {
		function clickHandler(e: MouseEvent) {
			const root = rootRef.current;
			const target = e.target as Node;

			if (!root?.contains(target))
				setIsOptionsVisible(false)
		}

		document.addEventListener('click', clickHandler)
		return () => document.removeEventListener('click', clickHandler)
	}, [setIsOptionsVisible, rootRef])

	function onClickDisplayOptions(e: React.MouseEvent<HTMLDivElement>) {
		if (!props.isReadonly) {
			e.stopPropagation()
			setIsOptionsVisible(!isOptionsVisible)
		}
	}

	return (
		<Styles ref={rootRef}>
			<DrawHeader label={label} onClick={onClickDisplayOptions} />

			<div className="options" data-is-visible={isOptionsVisible}>
				<ItemRow label="None" checked={currentFlag === 0} onChange={() => dispatch({type: 'clear'})}/>
				{
					getAllFlags()
						.filter(key => Permission[key] !== 'None')
						.map(flag => (
							<ItemRow
								key={flag}
								label={Permission[flag]}
								checked={(flag & currentFlag) == flag}
								onChange={newState => {
									dispatch({
										type: 'toggle',
										payload: { toggleState: newState, flag }
									})
								}}
							/>
						))
				}
			</div>
		</Styles>
	)
}

function DrawHeader(props: { label: string, onClick: (e: React.MouseEvent<HTMLDivElement>) => void }) {
	return (
		<div onClick={props.onClick}>
			<select style={{pointerEvents: 'none'}}>
				<option>{props.label}</option>
			</select>
		</div>
	)
}

interface ItemRowProps {
	label: string
	checked: boolean
	onChange: (checked: boolean) => void
}

function ItemRow({label, checked, onChange}: ItemRowProps) {

	function handleChange(e: ChangeEvent<HTMLInputElement>) {
		return onChange(e.target.checked);
	}

	return (
		<label>
			<input type="checkbox" checked={checked} onChange={handleChange}/>
			{ label }
		</label>
	)
}


function getAllFlags() {
	return Object.keys(Permission)
		.filter(k => (parseInt(k) >= 0))
		.map(k => parseInt(k))
}

function computeLabelFromFlags(currentFlag: number) {
	const permissionFlags = getAllFlags()

	const max = permissionFlags.length - 1;
	const activeFlagsCount = permissionFlags
		.filter(key => key !== 0 && (key&currentFlag) == key)
		.length

	switch (activeFlagsCount) {
		case 0:         return "None";
		case 1:         return Permission[currentFlag]
		case max:       return "Everything"
		default :       return "Mixed"
	}
}