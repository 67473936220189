import React from "react";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "Store/hooks";
import styled from "styled-components";
import OrgSelectorDropdown from "Templates/Default/OrgSelectorDropdown";
import {selectActiveOrg} from "../../../Store/selectors";

const OrgViewStyles = styled.div`
  display: flex;
  justify-self: center;

  .create-org-button {
    // Remove default styling
    border: none;
    background-color: transparent;

    font-size: 1.5em;
    padding: 0 0.25em;

    // Center this fucking button text
    height: 100%;
    line-height: 100%;

    cursor: pointer;
  }
`

export default function OrgSelectorDropdownWithCreate() {

	const navigate = useNavigate()
	const activeOrg = useAppSelector(selectActiveOrg);

	if (!activeOrg)
		return null

	return (
		<OrgViewStyles id='dropdown'>
			<OrgSelectorDropdown/>
			<button
				style={{fontSize: '2em'}} className="create-org-button c-xr--dark default"
				onClick={() => navigate('/organization/create')}
			>
				+
			</button>
		</OrgViewStyles>
	)
}