import styled from "styled-components";

export const Styles = styled.div`
  position: relative;

  .options {
	  width: 10rem;
	  
    position: absolute;
    top: 0;

    padding: 0 0.25rem 0.25rem;

    z-index: 10;

    border: 1px solid var(--dark1);
    border-radius: 3pt;
    background-color: var(--dark0);
	  
	  &[data-is-visible="false"] {
		  visibility: hidden;
	  }

    label {
      display: block !important;
      text-align: left !important;
      line-height: initial !important;
    }

    input {
      width: 1rem;
	    height: 1rem;
	    margin-right: 0.25rem;
      accent-color: var(--xr-light);
    }
  }
`