import { selectOrgCount } from "Store/selectors"
import {useAppSelector} from "../Store/hooks";

export default function useOrgCount() {

	const orgCount = useAppSelector(selectOrgCount);
	const orgLoadState = useAppSelector(state => state.organizations.loadState)

	return [
		orgLoadState === 'Completed',
		orgCount
	]
}