import React, { ReactElement } from "react";
import styled from "styled-components";

const CardStyles = styled.div`
	font-size: 1.5rem;
	
	display: grid;
  grid-template: 
		"label label"  2em
		"icon content" auto
		/ 40% auto
	;
	
	align-items: center;
	justify-items: center;

	line-height: 1.25em;
	
	cursor: pointer;
	background-color: #4d4d4d;
	
	border-radius: 5pt;
	outline: 1px solid rgba(0, 0, 0, 0.25);
	box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.25);

	svg {
		grid-area: icon;
		padding: 10px;
		max-width: 100%;
		max-height: 100%;
    
		* { fill: var(--xr-dark); }
	}
`

export interface CardBaseProps {
	imageSvg?: ReactElement
	label: string
	children?: ReactElement | any
}

export interface CardCallback {
	onClick?: () => void
}

export default function CardBase(props: CardBaseProps & CardCallback) {

	return (
		<CardStyles onClick={props.onClick} className="card">

			<p className="card-label" style={{gridArea: 'label'}}> {props.label} </p>

			{ props.imageSvg }

			<div className="card-content" style={{gridArea: 'content'}}>
				<div> {props.children} </div>
			</div>
		</CardStyles>
	)
}