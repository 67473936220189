import { msalInstance } from "index";
import { loginRequest } from "authConfig";
import { SilentRequest } from "@azure/msal-browser"

export default async function getToken(): Promise<string | undefined> {

	// This will wait until msal is ready
	await msalInstance.handleRedirectPromise()
	const allAccounts = msalInstance.getAllAccounts();

	if (allAccounts.length == 0) {
		return undefined
	}

	const accessTokenRequest: SilentRequest = {
		scopes: loginRequest.scopes,
		account: allAccounts[0]
	}

	return msalInstance.acquireTokenSilent(accessTokenRequest)
		.then(value => value.accessToken)
}