import { Organization } from "Models";
import { useAppDispatch, useAppSelector} from "Store/hooks";
import { organizationActions} from "Store/organizations.slice";
import { selectActiveOrg, organizationSelector } from "Store/selectors"
import { ItemStyles, Style } from "./OrgSelectorDropdown.styles";

export default function OrgSelectorDropdown() {

	const activeOrg = useAppSelector(selectActiveOrg)
	const enableDropdown = useAppSelector(organizationSelector.selectTotal) > 1

	return (
		<Style tabIndex={-1} data-enable-dropdown={enableDropdown}>
			<div className="active-org">
				{
					activeOrg
						? <OrgSelectorDropdownItem {...activeOrg}/>
						: <OrgSelectorDropdownEmpty/>
				}

				{
					enableDropdown && (
						<span className="arrow c-xr--dark">&#9662;</span>
					)
				}
			</div>

			<DrawDropdownListItems enable={enableDropdown}/>

		</Style>
	)
}

function DrawDropdownListItems(props: {enable: boolean}) {
	const orgList = useAppSelector(organizationSelector.selectAll);

	if(!props.enable)
		return null;

	return (
		<div className="other-orgs">
			{
				// Draw every other organization that is not the active one
				orgList.map(org => (
					<OrgSelectorDropdownItem key={org.id} {...org} />
				))
			}
		</div>
	)
}

function OrgSelectorDropdownItem(props: Organization) {

	const {id, name, thumbnailUrl} = props
	const activeOrg = useAppSelector(selectActiveOrg)
	const dispatch = useAppDispatch()

	function onUserClick(e: React.MouseEvent<HTMLDivElement>) {
		if (id == activeOrg?.id)
			return

		e.currentTarget.blur()
		dispatch(organizationActions.setActiveOrganization(id))
	}

	return (
		<ItemStyles tabIndex={-1} onClick={onUserClick}>
			<img src={ thumbnailUrl } alt=''/>
			<span> { name } </span>
		</ItemStyles>
	)
}

function OrgSelectorDropdownEmpty() {
	return (
		<ItemStyles>
			<img src="https://via.placeholder.com/200" alt="O"/>
			<span> NO ORG </span>
		</ItemStyles>
	)
}