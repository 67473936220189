import GenericConditionFilter from "Components/GenericConditionFilter"
import useOrgCount from "Hooks/useOrgCount"
import { selectActiveOrg } from "Store/selectors"
import styled from "styled-components";
import React, { ReactElement } from "react";
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from "Store/hooks";
import { useViewMode } from "Providers/ViewModeContextProvider";
import { IconDashboard, IconEditParameters, IconEditPlans, IconInstaller, IconLicense, IconUsers } from "Icons";
import PermissionUtils from "Utilities/permissionUtils";

const Styles = styled.nav`
  height: calc(100vh - 4rem);
  width: 4rem;
  background-color: var(--dark1);

  .sidebar-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid var(--dark0);
    height: 4em;
    text-decoration: none;

    svg {
      max-width: 2rem;
    }

    &[data-is-active="true"] * {
      fill: var(--xr-light);
      color: var(--xr-light);
    }

    &[data-is-active="false"] * {
      fill: var(--gray1);
      color: var(--gray1);
    }
  }
`

export default function SideBar(props: { id?: string }) {

	const viewMode = useViewMode();
	const activeOrg = useAppSelector(selectActiveOrg);
	const canView = !!activeOrg && PermissionUtils.hasView(activeOrg.permissions)

	return (
		<Styles id={props.id} style={{flexShrink: 0}}>
			<GenericConditionFilter condition={!!activeOrg}>
				<GenericConditionFilter condition={canView}>
					<SidebarItem iconSvg={<IconDashboard/>} url="/dashboard"/>
					<SidebarItem iconSvg={<IconLicense/>} url="/license"/>
					<SidebarItem iconSvg={<IconUsers/>} url="/users"/>
				</GenericConditionFilter>

				<SidebarItem iconSvg={<IconEditParameters/>} url="/organization/edit"/>
			</GenericConditionFilter>

			<GenericConditionFilter condition={viewMode.mode == "Admin"}>
				<SidebarItem iconSvg={<IconEditPlans/>} url="/plans"/>
				<SidebarItem iconSvg={<IconInstaller/>} url="/installers"/>
			</GenericConditionFilter>
		</Styles>
	)
}

interface ISidebarItemProps {
	url?: string
	iconSvg?: ReactElement
}

function SidebarItem({iconSvg, url}: ISidebarItemProps) {

	const location = useLocation();
	const [isReady, orgCount] = useOrgCount()

	const isActive = location.pathname === url;
	const finalUrl = isReady && orgCount == 0
		? '#'
		: (url ?? '#')

	return (
		<Link to={finalUrl} className='sidebar-item' data-is-active={isActive}>
			{iconSvg}
		</Link>
	)
}