import IconSearch from "Assets/icons/Search_ICON.svg";
import styled from "styled-components";

export const UsersPageStyles = styled.div`
  display: grid;
	grid-template-rows: min-content min-content auto;

  padding: 2rem;
	min-height: 94%;
	
	font-size: 1.2rem;
  background-color: #4d4d4d;
	
	tbody td:last-child {
		display: flex;
		gap: 1rem;
		align-items: center;
		justify-content: flex-end;

		height: 2.1rem;		
		padding-right: 1rem;
	}

  .search-field {
	  position: relative;

    width: 50ch;
    margin-top: 1em;
    margin-bottom: 0.25em;

	  input { 
		  width: 100%;
      font-size: 1rem;
	  }
	  
	  ::after{
		  content: url(${IconSearch});
		  
		  display: block;
		  position: absolute;
		  
		  top: 0.2em;
		  right: 0;		  
		  
		  width: 20px;
		  height: 20px;
	  }
  }
`

// language=SCSS
const mixing = `
	&{
    border: none;
    appearance: none;
    pointer-events: none;
    background-color: transparent;
  }  
`

export const TableRowStyles = styled.tr`
	//input { padding: 0 0.5em; }
  select { text-align: center; }

  input, select {
    width: 90%;
    font-size: 1rem;
  }

  &.view-disabled * {
    color: #777777;
  }

  &.view, &.view-disabled {
    input, select {
      ${mixing}
    }
  }

  &.edit [name="displayName"] {
    
  }

  .column-email {
    position: relative;

    .error-message {
      position: absolute;
      
      font-size: 1rem;
      line-height: 1rem;
      
      left: 50%;
      bottom: calc(60% + 0.5rem);
      transform: translateX(-50%);

      color: var(--red-custon);
      background: rgb(0, 0, 0, 0.5);
      
      padding: 0 0.5rem;
      border-radius: 5px;

      white-space: nowrap;
      pointer-events: none;
    }
  }
`