import {createRef, useEffect, useMemo} from "react";
import Chart, {TooltipItem} from 'chart.js/auto'
import {DateTime} from "luxon"

import Panel from "Components/Panel";
import {Analytics} from "Store/currentOrgStats.slice"
import {useAppSelector} from "Store/hooks"

export interface GenericUsageGraphPanelProps<T> {
	title: string
	selectors: GraphDataSelectors<T>
}

export interface GraphDataSelectors<T> {
	getItems: (source: Analytics) => T[]
	getItemDate: (item: T) => string
	getItemDuration: (item: T) => number
}

export default function GenericUsageGraphPanel<T>({title, selectors}: GenericUsageGraphPanelProps<T>) {

	const analytics = useAppSelector(s => s.currentOrgStats)
	const canvasRef = createRef<HTMLCanvasElement>();

	const [dailyData, accumulatedData] = useMemo(() => {
		if (!analytics)
			return []

		return [
			computeDailyUsage(analytics, selectors),
			computeAccumulatedUsage(analytics, selectors)
		]
	}, [analytics])

	useEffect(() => {
		const context = canvasRef.current!.getContext('2d')!;
		const chart = new Chart(context, {
			type: 'line',
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: true,
						labels: {
							color: '#fff'
						},
					},
					tooltip: {
						callbacks: {
							label(tooltipItem: TooltipItem<"line">): string | string[] {
								return tooltipItem.formattedValue + " min"
							},
							title(tooltipItems: TooltipItem<"line">[]): string | string[] {
								// @ts-ignore
								return DateTime.fromISO(tooltipItems[0].raw.date).toLocaleString()
							}
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: 'white',
						}
					},
					y: {
						ticks: {
							color: 'white'
						},
						min: 0
					}
				}
			},
			data: {
				datasets: [{
					label: 'Accumulated',
					data: accumulatedData,
					borderColor: "#02a59b",
					backgroundColor: "#02a59b",
					tension: 0.4,
					cubicInterpolationMode: 'monotone',
				},
				{
					label: 'Daily',
					data: dailyData,
					borderColor: "#649CE5",
					backgroundColor: "#649CE5",
					tension: 0.4,
					cubicInterpolationMode: 'monotone'
				}]
			}
		})

		return () => chart.destroy()
	}, [canvasRef])

	return (
		<Panel title={title}>
			<div style={{position: 'relative', width: '100%', height: '100%', padding: '8px'}}>
				<canvas ref={canvasRef}/>
			</div>
		</Panel>
	)
}

function aggregateDurationPerDate<T>(source: Analytics, selectors: GraphDataSelectors<T>) {
	return selectors
		.getItems(source)
		.reduce((mapper, data) => {

			const duration = selectors.getItemDuration(data)
			const key = DateTime.fromISO(selectors.getItemDate(data)).toISODate()

			const previousValue = mapper.get(key) ?? 0
			return mapper.set(key, previousValue + duration)

		}, new Map<string, number>())
}

function addEmptyDatesInBetween(analytics: Analytics, mappedData: Map<string, number>) {
	const min = DateTime.fromISO(analytics.activations.fromDate)
	const max = DateTime.fromISO(analytics.activations.toDate)

	const totalDaysInBetween = max.diff(min, "days").days

	for (let i = 0; i < totalDaysInBetween; i++) {
		const isoDate = min.plus({day: i}).toISODate()

		if (!mappedData.has(isoDate)) {
			mappedData.set(isoDate, 0)
		}
	}

	return mappedData
}

function sortDates(mappedData: Map<string, number>) {
	return Array
		.from(mappedData.entries())
		.map(([isoDateRaw, usages]) => ({
			date: DateTime.fromISO(isoDateRaw),
			usages
		}))
		.sort((a, b) => {
			return a.date > b.date ? 1 : -1
		})
}

function computeAndShit<T>(source: Analytics, selectors: GraphDataSelectors<T>){
	return sortDates(addEmptyDatesInBetween(source, aggregateDurationPerDate(source, selectors)))
}

function computeAccumulatedUsage<T>(source: Analytics, selectors: GraphDataSelectors<T>) {
	let accumulator = 0
	return computeAndShit(source, selectors)
		.map(({date, usages}, index, array) => {
			const previousValue = array[index - 1]?.usages ?? 0

			return {
				date,
				x: date.toLocaleString({day: '2-digit', month: 'short'}),
				y: Math.round((accumulator += previousValue) + usages)
			}
		})
}

function computeDailyUsage<T>(source: Analytics, selectors: GraphDataSelectors<T>) {
	return computeAndShit(source, selectors)
		.map(({date, usages}) => {
			return {
				date,
				x: date.toLocaleString({day: '2-digit', month: 'short'}),
				y: Math.round(usages)
			}
		})
}