import {Dictionary} from "@reduxjs/toolkit";
import {Field, Form, Formik, FormikHelpers, useFormikContext} from "formik";
import useWarningPopup from "Hooks/useWarningPopup.hook";
import {DateTime} from "luxon";
import {Styles} from "./ActivePlanEditor.styles"
import {ChangeEvent} from "react";
import LicenseRemote from "Store/async/license.remote";
import {useAppDispatch, useAppSelector} from "Store/hooks";
import {planAdapter} from "Store/plans.slice";
import useLoadingProgress from "../../Hooks/useLoadingProgress";

interface FormProps {
    planId: string
    creationDate: string
    seats : number
    expirationDate: string
    isActive: boolean
}

export default function ActivePlanEditor() {

    const dispatch = useAppDispatch();
    const progress = useLoadingProgress();
    const warningPopup = useWarningPopup();

    const activeOrg = useAppSelector(s => s.organizations.current);
    const activeLicense = useAppSelector(s => s.organizationLicense.current)
    const activePlan = activeLicense?.plan

    function tryParseDate(defaultValue: DateTime, rawDate?: string) {
        if (rawDate)
            return DateTime.fromISO(rawDate).toISODate()

        return defaultValue.toISODate()
    }

    function handleValidate(values: FormProps) {
        const errors: Dictionary<string> = {}

        if (values.planId === 'null')
            errors.planId = 'planId must be selected'

        return errors
    }

    function handleSubmit(values: FormProps, formikHelpers: FormikHelpers<FormProps>) {
        // This organization does not own a license yet, let's create a new one for them
        if (!activeLicense) {
            progress.start()
            dispatch(LicenseRemote.create({
                planId: values.planId,
                groupId: activeOrg!.id,
                seats: values.seats,
                expirationDate: values.expirationDate
            }))
                .finally(progress.stop)
        }
        // At this point, you are only changing some info about the license (active state or expiration date)
        else {
            progress.start()
            dispatch(LicenseRemote.update({
                licenseId: activeLicense.id,
                planId: values.planId,
                isActive: values.isActive,
                seats: values.seats,
                expirationDate: values.expirationDate
            }))
                .finally(progress.stop)
        }
    }

    return (
        <Styles>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    planId: activePlan?.id ?? 'null',
                    creationDate: tryParseDate(DateTime.now(), activeLicense?.creationDate),
                    seats: activeLicense?.seats,
                    expirationDate: tryParseDate(DateTime.now().plus({month: 1}), activeLicense?.expirationDate),
                    isActive: activeLicense?.isActive ?? true
                } as FormProps}

                validate={handleValidate}
                onSubmit={handleSubmit}
            >
                {(({values, handleBlur, setFieldValue, ...formikHelper}) => (
                    <Form>
                        <div className="form-container">
                            {/* Active Plan */}
                            <div className="plan-item">
                                <span className="c-xr"> Plan: </span>
                                <DrawPlanSelector/>
                            </div>

                            {/* Creation Date */}
                            <div className="plan-item">
                                <span className="c-xr"> Seats: </span>
                                <Field name="seats" type='number' required/>
                            </div>

                            {/* Expiration Date */}
                            <div className="plan-item">
                                <span className="c-xr"> Expire: </span>
                                <Field name="expirationDate" type='date' min={values.creationDate} required/>
                            </div>

                            {/* Active dropdown */}
                            <div className="plan-item">
                                <span className="c-xr"> Status: </span>

                                <select
                                    value={values.isActive ? '1' : '0'}
                                    onBlur={handleBlur}
                                    onChange={e => setFieldValue('isActive', e.target.value == '1')}
                                >
                                    <option value="0">Disabled</option>
                                    <option value="1">Active</option>
                                </select>
                            </div>
                        </div>
                        <button type="submit"> Save</button>
                    </Form>
                ))}
            </Formik>
        </Styles>
    )
}

function DrawPlanSelector() {
    const {values, setFieldValue} = useFormikContext<FormProps>();

    const availablePlans = useAppSelector(s => s.plans);
    const activePlan = useAppSelector(s => s.organizationLicense.current)?.plan

    function handleSelectorChanged(e: ChangeEvent<HTMLSelectElement>) {
        const created = DateTime.now()
        const expire = DateTime.now().plus({day: 10})

        setFieldValue('planId', e.target.value)

        if (!activePlan) {
            setFieldValue('creationDate', created.toISODate())
            setFieldValue('expirationDate', expire.toISODate())
        }
    }

    return (
        <select value={values.planId} onChange={handleSelectorChanged}>
            {
                planAdapter.getSelectors().selectAll(availablePlans)
                    .map(p => <option key={p.id} value={p.id}> {p.name} </option>)
            }

            {!activePlan && <option value="null"> NO PLAN </option>}
        </select>
    )
}