import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { User } from "Models";

export interface BaseArg {
	orgId: string
}

export interface CreateUserArg {
	email: string
	displayName: string
	isActive: boolean
	permissions: number
}

export interface DeleteUserArg {
	userId: string
}

export interface UpdateUserArg {
	userId: string
	isActive: boolean
	permissions: number
}

export default {
	create: createAsyncThunk<User, CreateUserArg & BaseArg>('user/create', (arg) => {
		const {orgId, ...data} = arg;
		return axios.post<User>(`organizations/${ orgId }/users`, data).then(res => res.data)
	}),

	list: createAsyncThunk<User[], BaseArg>('user/list', (arg) => {
		const {orgId} = arg;
		return axios.get(`organizations/${ orgId }/users`).then(res => res.data)
	}),

	update: createAsyncThunk<void, UpdateUserArg & BaseArg>('user/update', (arg) => {
		const {orgId, userId, ...data} = arg;
		return axios.put(`organizations/${ orgId }/users/${ userId }`, data)
	}),

	delete: createAsyncThunk<void, DeleteUserArg & BaseArg>('user/delete', (arg) => {
		const {orgId, userId} = arg;
		return axios.delete(`organizations/${ orgId }/users/${ userId }`)
	}),
}