import {useState} from "react"
import styled from "styled-components"
import {PricingCardProps} from "Components/PricingTable/types"
import PriceGroupToggle from "Components/PricingTable/PriceGroupToggle"
import PricingCard from "Components/PricingTable/PricingCard"
import {LoadingGif} from "../../Icons";

const Container = styled.div`
  .price-toggle-container {
    display: grid;
    place-items: center;
  }

  .cards-container {
    display: grid;
    grid-gap: 10px;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(312px, 312px));

    align-items: center;
    justify-content: center;

    width: 100%;
  }
`

const LoadingContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  height: 544.78px;
`

export type FetchDataResponse = Record<string, PricingCardProps[]>

interface PricingTableProps
{
	cardArgs: FetchDataResponse
	disableBuyButton: boolean

	onUserClickBuy?: (pricingId: string, isFree : boolean) => void
}


export default function PricingTable(props: PricingTableProps)
{
	const [activePriceGroup, setActivePriceGroup] = useState(1)

	const options = Object.keys(props.cardArgs).reverse()
	let cardData: PricingCardProps[] | undefined = props.cardArgs[options[activePriceGroup]]

	if (!cardData)
	{
		return (<LoadingContainer>
			<img width={100} height={100} src={LoadingGif}/>
		</LoadingContainer>)
	}

	return (
		<Container>

			<div className="price-toggle-container">
				<PriceGroupToggle options={options} active={activePriceGroup}
				                  onChangeActive={(index) => setActivePriceGroup(index)}/>
			</div>

			<br/>

			<div className="cards-container">
				{
					cardData.map((cData) =>
						<PricingCard
							key={cData.priceId} {...cData}
							disableBuyButton={props.disableBuyButton} onClickBuy={() => props.onUserClickBuy?.(cData.priceId, !cData.hasPrice)}/>
					)
				}
			</div>
		</Container>
	)
}