import styled from "styled-components"

export const Styles = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  height: 100%;

  form {

    .form-container {
      display: grid;
      //width: 100%;
      //height: 100%;
      background-color: #444;
      gap: 1rem;
      padding: 0.9rem 2rem;
    }

    button {
      
      display: block;
      height: 2rem;
      width: 200px;

      margin: auto;
      margin-top: 10px;

      font-size: 1.25rem;
      line-height: 0;
    }

    .plan-item {
      display: grid;
      gap: 1rem;
      grid-template-columns: 8ch 21ch;

      align-items: center;
      justify-content: center;

      span {
        font-size: 1.25em;
      }

      input, select {
        font-size: 1.2em;
      }
    }
  }
`