import useDashboardStats from "Services/DashboardStats.service";
import CardBase, { CardCallback } from "./CardBase";
import React from "react";
import { ReactComponent as IconLicense } from 'Assets/icons/License_ICON.svg'
import {DateTime} from "luxon";

export default function LicenseCard(props: CardCallback) {

	const {expirationDateRaw, planName} = useDashboardStats().license;

	const expirationDate = !expirationDateRaw
		? ''
		: DateTime.fromISO(expirationDateRaw).toFormat('dd/MM/yyyy');

	return (
		<CardBase {...props} label="License" imageSvg={<IconLicense/>}>

			<p className="t-center f-normal">{ planName ?? 'No license' }</p>
			<p className="t-center f-normal"> { expirationDate ?? '' } </p>
			<p className="t-center c-xr f-small">Expire date</p>

		</CardBase>
	)
}