import {AppState} from "Store/hooks"
import {organizationAdapter} from "Store/organizations.slice"
import {orgUserAdapter} from "Store/organizationUsers.slice"
import {License} from "../Models";

export const orgUsersSelector = orgUserAdapter.getSelectors((state: AppState) => state.orgUsers)
export const organizationSelector = organizationAdapter.getSelectors((state: AppState) => state.organizations)

export const selectActiveOrg = (state: AppState) => state.organizations.current
export const selectActiveOrgLicense = (state: AppState) => state.organizationLicense.current
export const selectAllOrgs = (state: AppState) => organizationSelector.selectAll(state);
export const selectOrgCount = (state: AppState) => organizationSelector.selectTotal(state);

/**
 * Check if the application is done fetching remote data (only works for authenticated users)
 */
export const selectIsAuthDataReady = (state: AppState) => state.appState.isInitializationReady