import styled from "styled-components"

const Container = styled.div`
	
	display: inline-flex;

  background-color: hsla(0, 0%, 100%, .1);
	border: 1px solid hsla(0,0%,100%,.2);
  border-radius: 8px;
  padding: 2px;

  button {
    line-height: 20px;
    padding: 8px 32px;
  }

  button:not([data-active=true]) {
    background-color: transparent;
  }
`

interface Props {
	active: number
	options: string[]

	onChangeActive: (index: number) => void
}

export default function PriceGroupToggle(props: Props) {
	return (
		<Container>

			{
				props.options.map((value, index) => (
					<button key={value} data-active={props.active == index} onClick={() => props.onChangeActive(index)}>
						{"Per " + value}
					</button>
				))
			}
		</Container>
	)
}