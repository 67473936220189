import {useMsal} from "@azure/msal-react";
import useRandomColor from "Hooks/useRandomColor.hook";
import React from "react";
import styled from "styled-components";

const UserProfileImageAndLoginInfoStyles = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  gap: 1em;
  align-items: center;
  align-content: center;
  z-index: 5;

  :not(:focus-within) .extra-user-info {
    display: none;
  }

  .extra-user-info {
    cursor: initial;
    position: absolute;
    top: calc(100% + 1em);
    right: 0;

    padding: 1em 2em;

    border-radius: 0.5em;
    border: 1px solid var(--gray1);
    background-color: var(--dark1);

    text-overflow-mode: none;

    white-space: nowrap;
  }
`

export default function UserProfileImageAndLoginInfo()
{
	const msal = useMsal();
	const randomColor = useRandomColor();
	const accounts = msal.accounts;

	if (accounts.length == 0)
		return null;

	const personName = accounts[0].name as string;

	return (
		<UserProfileImageAndLoginInfoStyles tabIndex={0}>
			<img
				src={`https://ui-avatars.com/api/?name=${personName}&rounded=true&size=30&background=${randomColor(personName)}&color=ffffff`}
				alt="profile"/>

			<div className="extra-user-info t-center">
				<br/>
				<div>
					<h2> {personName} </h2>
					<p> {accounts[0].username} </p>
				</div>
				<br/>
				<br/>

				<a className="c-xr" style={{cursor: 'pointer'}} onClick={() => msal.instance.logoutRedirect()}>
					Sing Out
				</a>
			</div>
		</UserProfileImageAndLoginInfoStyles>
	);
}