import styled from "styled-components";

export const Style = styled.div`
	--arrow-scale: 2.75;

	position: relative;
	
	&[data-enable-dropdown="true"] {
		cursor: pointer;
	}
	
	:not(:focus-within) {
		.other-orgs { display: none; }			
		.active-org .arrow { transform: rotate(0) scale(var(--arrow-scale)); }		
	}

	.active-org {
		display: flex;
		justify-content: space-between;
		
		width: 23em;
		padding: 0.25em 0.75em;
		border-radius: 0.15em;		
		
		background-color: #333333;
		
		.arrow {			
			user-select: none;
			align-self: center;
			transform: rotate(180deg) scale(var(--arrow-scale));
		}		
	}
	
	.other-orgs {
		position: absolute;
		
		//top: 2em;
		top: 100%;
		left: 7%;
		right: 0;
		padding: 0.25em 1em;
		
		z-index: 999;
		
		border-radius: 0.35em;
		
		background-color: var(--dark1);
		
		> * {
			margin: 0.25em 0;
			width: 100%;
		}
	}
`

export const ItemStyles = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;

	cursor: pointer;
	font-size: 1.25em;

	img {
		width: 1.25em;
		height: 1.25em;
		border-radius: 50%;		
	}
	
	span {		
		overflow: hidden;
		white-space: nowrap;
	}
`