import React from "react"

interface GenericConditionFilterParams {
	children: React.ReactNode;
	condition: boolean | null | undefined;
	renderOnFail?: React.ReactNode;
}

export default function GenericConditionFilter(props: GenericConditionFilterParams) {

	const render = props.condition
		? props.children
		: (props.renderOnFail ?? null)

	return (
		<>
			{render}
		</>
	)

}