import { configureStore } from '@reduxjs/toolkit'
import { currentOrgStatsSlice } from "Store/currentOrgStats.slice";
import { loadingProgressSlice } from "Store/loadingProgress.slice";
import { notificationSlice } from "./notification.slice";

import { plansSlice } from "Store/plans.slice";
import { installersSlice } from "Store/installers.slice";
import { organizationsSlice } from "Store/organizations.slice";
import { organizationUsersSlice } from "Store/organizationUsers.slice";
import { organizationLicenseSlice } from "Store/organizationLicense.slice";
import {appStateSlice} from "./appStateSlice";

export const store = configureStore({
	reducer: {
		plans: plansSlice.reducer,
		installers: installersSlice.reducer,
		orgUsers: organizationUsersSlice.reducer,
		organizations: organizationsSlice.reducer,
		currentOrgStats : currentOrgStatsSlice.reducer,
		organizationLicense: organizationLicenseSlice.reducer,

		notification: notificationSlice.reducer,
		globalLoading: loadingProgressSlice.reducer,
		appState: appStateSlice.reducer
	}
})