import styled from "styled-components";
import PricingPage from "./index";
import {PageContent} from "../../Layouts/Default";

export const StyledContent = styled(PageContent)`
{
  h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  padding: 1rem 4rem !important;
  
  .agreement {

    margin-top: 10px;
    text-align: center;
    
    a {
        color: var(--xr-light);
    }
  }
  
  .contact-us {

    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
    
    a {
        color: var(--xr-light);
        text-decoration: none;
    }
  }
}
`

export const StyledHeader = styled.div`
{
  margin-left: 2rem;
  
  h1 {
    font-size: 2.5rem;
  }
  span {
    color: var(--xr-light);
    font-size: 1.05rem;
  }
`