import {IconInfinite} from "Icons"
import useDashboardStats from "Services/DashboardStats.service";
import CardBase, { CardCallback } from "./CardBase";
import { ReactComponent as IconAudio } from 'Assets/icons/SoundTime_ICON.svg'

export default function AudioCard(props: CardCallback){

	const {usage, max, usagePercent} = useDashboardStats().audio

	return (
		<CardBase {...props} label="Audio" imageSvg={<IconAudio/>}>
			<p className="t-center">
				<span className="f-big">{ usagePercent }</span>
				<span className="f-normal">%</span>
			</p>

			<p className="t-center f-small c-xr">
				{ `${usage.toFixed()} Min ` }

				/

				{ max == -1
					? <IconInfinite className='c-xr'/>
					: ` ${max.toFixed()} Min`
				}
			</p>
		</CardBase>
	)
}