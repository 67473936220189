import SelectEnumFlags from "./SelectEnumFlags";
import {ErrorMessage, Field, Formik, FormikHelpers, useFormikContext} from "formik";
import useLoadingProgress from "Hooks/useLoadingProgress";
import {User} from "Models";
import {TableRowStyles} from "./UsersPage.styles";
import {ChangeEvent, createContext, useContext, useMemo, useState} from "react";
import {useAppDispatch, useAppSelector} from "Store/hooks";
import {DrawActionButtons} from "./DrawActionButtons";
import {saveChanges} from "./utility";
import * as Yup from 'yup'

export type DisplayMode = 'create' | 'edit'
type ViewMode = "edit" | 'view' | 'view-disabled'

interface ITableRowProps
{
    user: User
    displayMode: DisplayMode
    canEdit: boolean
}

export interface RowContextProps
{
    user: User
    displayMode: DisplayMode

    isEditing: boolean
    setIsEditing: (state: boolean) => void
}

const CreateUserFormSchema = Yup.object().shape({
    email: Yup.string()
        .label("Email")
        .email()
        .required(),
    displayName: Yup.string()
        .label("Name")
        .required()
})

export const RowContext = createContext({} as RowContextProps)

export default function UsersTableRow(props: ITableRowProps)
{

    const dispatch = useAppDispatch();
    const progress = useLoadingProgress();

    const activeOrg = useAppSelector(s => s.organizations.current)

    const {displayMode, user} = props
    const [isEditing, setIsEditing] = useState(false)

    const viewMode: ViewMode = useMemo(() =>
    {
        if (props.canEdit && (isEditing || displayMode === 'create'))
            return 'edit'

        if (!user.isActive)
            return 'view-disabled'

        return 'view'
    }, [isEditing, displayMode])

    const handleFormSubmit = (values: User, formikHelpers: FormikHelpers<User>) =>
    {
        saveChanges(values, activeOrg!.id, displayMode, {formik: formikHelpers, progress, dispatch, setIsEditing})
    }

    return (
        <RowContext.Provider value={{user, displayMode, isEditing, setIsEditing}}>
            <Formik initialValues={user} onSubmit={handleFormSubmit} validationSchema={CreateUserFormSchema}>
                <TableRowStyles className={viewMode}>

                    {/* Display Name  */}
                    <td className={"column-email"}>
                        <ErrorMessage name='displayName' className="error-message" component="p"/>
                        <Field
                            name="displayName" type="text"
                            tabIndex={displayMode === 'create' ? 0 : -1} readOnly={displayMode === 'edit'}
                        />
                    </td>

                    {/* Email  */}
                    <td className='column-email'>
                        <ErrorMessage name='email' className="error-message" component="p"/>
                        <Field
                            name="email" type="email"
                            tabIndex={displayMode === 'create' ? 0 : -1}
                        />
                    </td>

                    {/* Permission */}
                    <td>
                        <DrawPermissionDropdown/>
                    </td>

                    {/* Status */}
                    <td>
                        <DrawUserStatus viewMode={viewMode}/>
                    </td>

                    {/* Action buttons */}
                    <td>
                        {
                            props.canEdit && (<DrawActionButtons/>)
                        }
                    </td>

                </TableRowStyles>
            </Formik>
        </RowContext.Provider>
    )
}

function DrawPermissionDropdown()
{

    const {displayMode, isEditing} = useContext(RowContext);
    const {values: {permissions}, setFieldValue} = useFormikContext<User>()

    return (
        <SelectEnumFlags
            isReadonly={displayMode == 'edit' && !isEditing}
            value={permissions}
            onChange={flags => setFieldValue('permissions', flags)}
        />
    )
}

function DrawUserStatus(props: { viewMode: ViewMode })
{

    const {values: {isActive}, setFieldValue, handleBlur} = useFormikContext<User>()

    function onChange(e: ChangeEvent<HTMLSelectElement>)
    {
        return setFieldValue('isActive', e.target.value === "1");
    }

    const tabIndex = props.viewMode == 'edit' ? 0 : -1;
    return (
        <select name="isActive" tabIndex={tabIndex} onBlur={handleBlur} value={isActive ? "1" : "0"}
                onChange={onChange}>
            <option value={"1"}>Active</option>
            <option value={"0"}>Disabled</option>
        </select>
    )

}