import { useViewMode, ViewMode } from "Providers/ViewModeContextProvider";
import React, { ChangeEvent } from "react";
import styled from "styled-components";

const UserViewModeSelectorStyles = styled.select`
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;

  // Additional resets for further consistency
  background-color: #1a1a1a;
  margin: 0;
  border: none;
  padding: 0 1em 0 0;
 
  cursor: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
`

export default function UserViewModeSelector() {

	const {mode, setViewMode, canChange} = useViewMode();

	function handleSelectionChanged(e: ChangeEvent<HTMLSelectElement>) {
		return setViewMode(e.target.value as ViewMode);
	}

	if (!canChange) {
		return null;
	}

	return (
		<UserViewModeSelectorStyles value={ mode } onChange={ handleSelectionChanged }>
			<option value="Admin"> Admin </option>
			<option value="User"> User </option>
		</UserViewModeSelectorStyles>
	)
}