import React, {Children} from 'react';
import styled from "styled-components";

const PanelStyles = styled.div`
	
	background-color: var(--gray0-alt);

  display: flex;
  flex-direction: column;
	
	.panel-label   { text-align: center; }
  .panel-content { flex-grow: 1; }
`

export interface IPanelProps extends React.HTMLAttributes<HTMLDivElement> {
	title: string
	expandContent?: boolean
}

export default function Panel({title, children, ...rest}: IPanelProps) {
	return (
		<PanelStyles {...rest} className={"panel-root " + rest.className}>
			<h2 className="panel-label">{title}</h2>

			<div className="panel-content">
				{children}
			</div>
		</PanelStyles>
	);
};