import React from "react";
import styled from "styled-components";
import useLoadingProgress from "Hooks/useLoadingProgress";

const ProgressBarElement = styled.div<{ animate: boolean }>`

  visibility: ${ ({animate}) => animate ? 'visible' : 'hidden' };
  position: absolute;

  top: 100%;
  left: 0;
  right: 0;

  ::after {
    display: block;
    content: '';

    height: 2px;

    background-color: var(--xr-dark);

    animation: loading 3s linear infinite;
    transform-origin: left center;
  }

  @keyframes loading {
    0% 		{ transform: translateX(0%) 	scaleX(0); 		}
    20% 	{ transform: translateX(0%) 	scaleX(0.2); 	}
    80% 	{ transform: translateX(80%) 	scaleX(0.2); 	}
    100% 	{ transform: translateX(100%) scaleX(0); 		}
  }
`

export default function ProgressBar() {
	const loadingProgress = useLoadingProgress();
	return <ProgressBarElement animate={ loadingProgress.isLoading }/>
}