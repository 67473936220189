import { NavigationClient } from "@azure/msal-browser"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import GenericConditionFilter  from "Components/GenericConditionFilter"

import useIsAdmin from "Hooks/useIsAdmin.hook"

import DefaultLayout from "Layouts/Default"
import DashboardPage from "Pages/DashboardPage"
import InstallersPage from "Pages/InstallersPage"
import LicensePage from "Pages/LicensePage"
import OrgEditionPage, { OrgEditMode } from "Pages/OrgEditionPage"
import PlansPage from "Pages/PlansPage"
import PricingPage from "Pages/PricingPage"

import UsersPage from "Pages/UsersPage"
import React from "react"
import { BrowserRouter, Navigate, NavigateFunction, Route, Routes, useNavigate } from "react-router-dom"
import { useAppSelector } from "Store/hooks";
import { selectActiveOrg } from "Store/selectors";
import PermissionUtils from "Utilities/permissionUtils";

export default function AppRoutes() {
	return (
		<BrowserRouter>
			<RouterItems/>
		</BrowserRouter>
	)
}

export interface ViewModeProps {
	edit: boolean;
}

function RouterItems() {
	const msal = useMsal()
	const navigate = useNavigate();
	const isAdmin = useIsAdmin();
	const isAuthenticated = useIsAuthenticated();
	const organization = useAppSelector(selectActiveOrg);

	msal.instance.setNavigationClient(new CustomNavigationClient(navigate))

	const canEditOrg = isAuthenticated && !!organization && PermissionUtils.hasEdit(organization.permissions);
	const canViewOrg = isAuthenticated && !!organization && PermissionUtils.hasView(organization.permissions);

	return (

		<Routes>
			<Route path="/" element={<DefaultLayout/>}>
				<Route path="pricing" element={<PricingPage/>}/>

				<GenericConditionFilter condition={isAuthenticated}>
					<Route path="organization/create" element={<OrgEditionPage canEdit={true} mode={OrgEditMode.createNew}/>}/>
				</GenericConditionFilter>

				<GenericConditionFilter condition={canViewOrg}>
					<Route path="dashboard" element={<DashboardPage/>}/>
					<Route path="users" element={<UsersPage edit={canEditOrg}/>}/>
					<Route path="license" element={<LicensePage edit={canEditOrg}/>}/>
					<Route path="organization/edit" element={<OrgEditionPage canEdit={canEditOrg} mode={OrgEditMode.editCurrent}/>}/>
				</GenericConditionFilter>

				<GenericConditionFilter condition={isAdmin} renderOnFail={<Navigate to={'/dashboard'}/>}>
					<Route path="plans" element={<PlansPage/>}/>
					<Route path="installers" element={<InstallersPage/>}/>
				</GenericConditionFilter>
			</Route>
		</Routes>
	)
}

class CustomNavigationClient extends NavigationClient {
	private readonly navigate: NavigateFunction;

	constructor(navigate: NavigateFunction) {
		super();
		this.navigate = navigate;
	}

	// This function will be called anytime msal needs to navigate from one page in your application to another
	async navigateInternal(url: string, options: { noHistory: any; }) {
		// url will be absolute, you will need to parse out the relative path to provide to the history API
		const relativePath = url.replace(window.location.origin, '');
		// if (options.noHistory) {
		// 	this.history.replace(relativePath);
		// } else {
		// 	this.history.push(relativePath);
		// }

		this.navigate(relativePath)

		return false;
	}
}