import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {License, LockedDevice} from "Models";
import LicenseRemote from "Store/async/license.remote";
import {organizationAdapter} from "./organizations.slice";
import SubscriptionRemote from "./async/subscription.remote";

const lockedDeviceAdapter = createEntityAdapter<LockedDevice>({
    selectId: model => model.deviceId
})

export const organizationLicenseSlice = createSlice({
    name: 'license',
    initialState: {
        current: <License | undefined | null>undefined,
        lockedDevices: lockedDeviceAdapter.getInitialState()
    },
    reducers: {
    },
    extraReducers: builder => builder
        .addCase(LicenseRemote.get.fulfilled, (state, action) =>
        {
            state.current = null

            if (action.payload)
            {
                const {...license} = action.payload
                state.current = license
            }
        })

        .addCase(LicenseRemote.create.fulfilled, (state, action) =>
        {
            state.current = action.payload
        })

        .addCase(LicenseRemote.update.fulfilled, (state, action) =>
        {
            const {licenseId, ...payload} = action.meta.arg;

            Object.assign(state.current, payload)
        })
        .addCase(SubscriptionRemote.subscribeFree.fulfilled, (state, action) =>
        {
            state.current = action.payload
        })
        .addCase(SubscriptionRemote.cancelFreeSubscription.fulfilled, (state, action) =>
        {
            console.log("canceled")
            state.current = null
        })
})