import React  from 'react';
import ReactDOM from 'react-dom';

import ProviderStack from "Providers/ProviderStack";
import Initialization from "Misc/Initialization"
import AxiosConfiguration from "Misc/AxiosConfiguration";

import { msalConfig } from "authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

import WarningPopup from "Popup/WarningPopup";
import NotificationPopupList from "Popup/NotificationPopupList";

import reportWebVitals from './reportWebVitals';
import './index.css'
import './globals'

import AppRoutes from "./routes";

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
	<React.StrictMode>
		<ProviderStack>
			<AxiosConfiguration />

			<AppRoutes />

			<WarningPopup />
			<NotificationPopupList />

			<Initialization/>

		</ProviderStack>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();