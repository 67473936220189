import Checkbox from "Components/Checkbox";
import Table from "Components/Table";
import {Field, Formik, useField, useFormikContext} from "formik";
import {FieldHookConfig} from "formik/dist/Field";
import useLoadingProgress from "Hooks/useLoadingProgress";
import {Plan} from "Models";
import {Styles} from "Pages/admin_parts/PlanEditor.styles";
import {useState} from "react";
import PlansRemote from "Store/async/plans.remote";
import {useAppDispatch} from "Store/hooks";

export interface PlanEditorPropsBase
{
    plan: Plan
    mode: 'edit' | 'create'
}

export interface IPlanEditorProps extends PlanEditorPropsBase
{
    onCancel?: () => void
}

export default function PlanEditor(props: IPlanEditorProps)
{

    const progress = useLoadingProgress();
    const dispatch = useAppDispatch();

    const {plan, mode, onCancel} = props;

    const cancelEdit = () => onCancel?.()

    return (
        <Formik
            initialValues={plan}
            onSubmit={async (values, formikHelpers) =>
            {

                try
                {
                    progress.start()

                    switch (mode)
                    {
                        case "edit":
                            await dispatch(PlansRemote.update(values))
                            break;

                        case "create":
                            await dispatch(PlansRemote.create(values))
                            break;
                    }
                } finally
                {
                    onCancel?.()
                    progress.stop()
                }
            }}
        >
            {
                ({values, ...formikProps}) => (

                    <Styles>
                        <div className="plan-info">

                            {/*Plan Name*/}
                            <label>
                                <span className="c-xr" style={{marginRight: '0.5rem'}}>Name:</span>
                                <Field name="name" type="text"/>
                            </label>

                            {/*Storage*/}
                            <label>
                                <span className="c-xr">Support Level:</span>

                                <select id="supportLevel" name="supportLevel"
                                        style={{margin: '0 0.5rem', width: '22.5ch'}}
                                        onChange={e => formikProps.setFieldValue('supportLevel', parseInt(e.target.value))}
                                        value={values.supportLevel}>
                                    <option value="0">Forum</option>
                                    <option value="1">Ticket</option>
                                    <option value="2">Chat</option>
                                    <option value="3">Phone</option>
                                </select>
                            </label>

                            <label>
                                <span className="c-xr">Storage:</span>
                                <input
                                    type="number" style={{margin: '0 0.5rem'}}
                                    value={values.storageSize / (1024 * 1024 * 1024)}
                                    onChange={e => formikProps.setFieldValue('storageSize', e.target.valueAsNumber * (1024 * 1024 * 1024))}
                                />
                                <span className="c-xr"> GB </span>
                            </label>
                        </div>

                        <b className="c-xr v-space"> Features: </b>

                        <Table>
                            <thead>
                            <tr>
                                <th>Type</th>
                                <th>Unlimited</th>
                                <th>Usage</th>
                            </tr>
                            </thead>

                            <tbody>
                            <RowEditor/>
                            <RowNumber name="playerCount" displayName="Player count"/>
                            <RowNumber name="audioMinutes" displayName="Audio minutes"/>
                            <RowNumber name="videoMinutes" displayName="Video minutes"/>
                            <FlagsRow name="features" flagValue={1} displayName="Html5 Cloud"/>
                            <FlagsRow name="features" flagValue={2} displayName="Html5 Export"/>
                            <FlagsRow name="features" flagValue={4} displayName="Video Export"/>
                            </tbody>
                        </Table>

                        <div className="action-buttons v-space">
                            <button onClick={formikProps.submitForm}> Save</button>
                            <button onClick={cancelEdit}> Cancel</button>
                        </div>

                    </Styles>
                )
            }
        </Formik>
    )
}

function RowEditor()
{
    const {values, ...formikContext} = useFormikContext<Plan>();

    const initialValue = formikContext.getFieldMeta<number>('editorCount').initialValue!

    const isUnlimited = values.editorCount <= -1
    const notUnlimitedValue = initialValue <= -1 ? 0 : initialValue;

    return (
        <tr>
            <td> Editor count</td>
            <td>
                <Checkbox
                    checked={isUnlimited}
                    onChange={e => formikContext.setFieldValue('editorCount', e.target.checked ? -1 : notUnlimitedValue)}
                />
            </td>
            <td> {isUnlimited ? (<>&infin;</>) : <Field name="editorCount" className={"feature"} type="number"/>} </td>
        </tr>
    )
}

function FlagsRow(props: FieldHookConfig<number> & { displayName: string, flagValue: number })
{
    const [field, meta, helpers] = useField(props)

    return (
        <tr>
            <td> {props.displayName} </td>
            <td>
                <Checkbox
                    checked={(field.value & props.flagValue) !== 0}
                    onChange={e =>
                    {
                        let value = field.value;
                        const checked = e.target.checked;
                        const flagValue = props.flagValue;
                        value += (checked ? flagValue : -flagValue);
                        helpers.setValue(value);
                    }
                    }
                />
            </td>
            <td>-</td>
        </tr>
    )
}

function RowNumber(props: FieldHookConfig<number> & { displayName: string })
{
    const [field, meta, helpers] = useField(props)

    const initialValue = meta.initialValue!

    const isUnlimited = field.value <= -1
    const notUnlimitedValue = initialValue <= -1 ? 0 : initialValue;

    return (
        <tr>
            <td> {props.displayName} </td>
            <td>
                <Checkbox
                    checked={isUnlimited}
                    onChange={e => helpers.setValue(e.target.checked ? -1 : notUnlimitedValue)}
                />
            </td>
            <td> {isUnlimited ? (<>&infin;</>) : <Field name={field.name} className={"feature"} type="number"/>} </td>
        </tr>
    )
}

function FlagsInput(props: FieldHookConfig<string[] | null>)
{

    const [isFocused, setFocused] = useState(false)
    const [field, meta, helpers] = useField(props);

    return (
        <div className={'input-container ' + (isFocused ? 'with-focus' : 'without-focus')}>
            <input
                type="text"

                value={field.value?.join(';') ?? ''}
                onChange={e => helpers.setValue(e.target.value.split(';'))}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
            />

            <div className='flag-items'>
                {
                    field.value && field.value
                        .filter(s => s.trim().length > 0)
                        .map((flag, index) => (
                            <span key={flag + index}> {flag} </span>
                        ))
                }
            </div>
        </div>
    )
}