import useDashboardStats from "Services/DashboardStats.service";
import CardBase, { CardCallback } from "./CardBase";
import { ReactComponent as IconUsers } from 'Assets/icons/Users_ICON.svg'

export default function UsersCard(props: CardCallback) {

	const {usersCount} = useDashboardStats().users;

	return (
		<CardBase {...props} label="Users" imageSvg={ <IconUsers />}>

			<p className="t-center f-big">{ usersCount }</p>

			{/*
			 * The transformation is a quick fix to make the label a little bit further down and to be aligned
			 *  with the label from other cars
			 */}
			<p className="t-center f-small c-xr" style={{transform: 'translateY(1rem)'}}> Active </p>

		</CardBase>
	)
}