import Table from "Components/Table";
import useLoadingProgress from "Hooks/useLoadingProgress";
import useWarningPopup from "Hooks/useWarningPopup.hook";
import { PageContent, PageTitle } from "Layouts/Default"
import { DateTime } from "luxon";
import { Installer } from "Models";
import { ChangeEvent, useEffect } from "react";
import styled from "styled-components";

import { IconDelete } from "Icons";
import { useAppDispatch, useAppSelector } from "Store/hooks";
import InstallersRemote from "Store/async/installers.remote";

const Styles = styled.div`
	
	background-color: var(--gray0-alt);

	display: grid;
	height: 94%;
	grid-template-rows: min-content auto;

  .table--root {
		padding: 2rem;
		font-size: 1.25em;
		
		select { font-size: 1em; }

	  th, td {
		  :first-child { padding-left: 5rem; }
		  :last-child { padding-right: 1rem; }
	    padding: 0.5rem 0;
	  }
		
	  .commit-id { width: 40ch; }
	  
	  .action {
		  display: flex;
		  align-items: center;
		  justify-content: center;

		  height: 100%;
	    
	    svg
        {
	      width: 35px;
	      height: 35px;
	    }
	  }
	}
`

export default function InstallersPage(){

	const installers = useAppSelector(state => Object.values(state.installers.entities));

	return (
		<>
			<PageTitle> Installers </PageTitle>
			<PageContent>
				<Styles>
					<h1 className='t-center'>Manage Installers </h1>

					<Table className='table--root'>
						<thead>
						<tr>
							<th className="c-xr version">Version</th>
							<th className="c-xr commit-id">Commit ID</th>
							<th className="c-xr date">Upload Date</th>
							<th className="c-xr status">Status</th>
						</tr>
						</thead>

						<tbody>
						{
							installers
								.sort((a, b) => {
									if (!a || !b)
										return 0

									const dateA = DateTime.fromISO(a.uploadDate)
									const dateB = DateTime.fromISO(b.uploadDate)

									return dateA < dateB ? 1 : -1
								})
								.map(installer => installer && (
									<Row key={ installer.version } data={ installer }/>
								))
						}
						</tbody>
					</Table>
				</Styles>
			</PageContent>
		</>
	)
}

const RowStyles = styled.tr`
	
	&[data-is-active="false"] .lockable {
    pointer-events: none;
    color: var(--gray2);
    fill: var(--gray2);
	}

  td { text-align: center; }

  .btn-action {
    border: none;
    background-color: transparent;

    cursor: pointer;
    width: 1.5rem;
    font-size: 1.2em;
  }
`

export interface IRowProps {
	data: Installer
}

function Row({data}: IRowProps) {

	const progress = useLoadingProgress();
	const dispatch = useAppDispatch();
	const warningPopup = useWarningPopup();

	useEffect(() => {
		console.log(`Draw row ${data.version}`)
	}, [])

	const handleClickDelete = () => {

		warningPopup.open({
			message: `Removing installer version ${data.version}`,
			challenge: {
				title: 'Type the version number to confirm removal',
				phrase: data.version
			},
			onSuccess: () => {
				progress.start()
				dispatch(InstallersRemote.delete(data.version))
					.finally(progress.stop)
			}
		})
	}

	const handleStatusChanged = (e: ChangeEvent<HTMLSelectElement>) => {
		const isTrue = e.target.value === 'true'

		progress.start()
		dispatch(InstallersRemote.update({
			isActive: isTrue,
			version: data.version
		}))
			.finally(progress.stop)
	}

	return (
		<RowStyles data-is-active={data.isActive}>
			<td className='lockable version'> { data.version } </td>
			<td className='lockable commit-id'> { data.commitId } </td>
			<td className='lockable date'> { DateTime.fromISO(data.uploadDate).toFormat('dd/MM/yyyy') } </td>
			<td className='status'>
				<select className="t-center" value={ data.isActive.toString() } onChange={ handleStatusChanged }>
					<option value="true">Active</option>
					<option value="false">Disabled</option>
				</select>
			</td>

			<td className='action'>
				<IconDelete className="btn-action fill-red" onClick={ handleClickDelete }/>
			</td>
		</RowStyles>
	)
}