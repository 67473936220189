import {Permission} from "Models";

export default class PermissionUtils {
	static hasEdit(base: Permission) {
		return PermissionUtils.contains(base, Permission.PortalManage)
	}

	static hasView(base: Permission) {
		return PermissionUtils.contains(base, Permission.PortalView)
	}

	static contains(base: Permission, perm: Permission) {
		return (base & perm) == perm
	}

	static getNumberFlags() {
		return Object.keys(Permission)
			.filter(k => (parseInt(k) >= 0))
			.map(k => parseInt(k))
	}
}