import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Installer } from "Models";

interface UpdateInstallerArgs {
	isActive: boolean
	version: string
}

export default {
	list: createAsyncThunk<Installer[]>('installers/list', async (arg) => {
		return axios.get<Installer[]>('Installer?includeInactive=true').then(res => res.data)
	}),

	delete: createAsyncThunk<void, string>('installer/delete', async (arg) => {
		await axios.delete(`Installer/${ arg }`)
	}),

	update: createAsyncThunk<void, UpdateInstallerArgs>('installer/setIsActive', async (arg) => {
		const {isActive, version} = arg
		await axios.put(`Installer/${ version }/Activate/${ isActive }`)
	})
}