import { v4 as uuid } from 'uuid'
import { addNotification, removeNotificationById } from "Store/notification.slice";
import { useAppDispatch } from "Store/hooks";

export default function useNotification() {

	const dispatch = useAppDispatch();

	return {
		add: (message: string) => dispatch(addNotification({id: uuid(), message})),
		remove: (id: string) => dispatch(removeNotificationById(id))
	}
}