import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { User } from "Models";
import orgUsersRemote from "Store/async/orgUsers.remote";

export const orgUserAdapter = createEntityAdapter<User>({
	selectId: model => model.id
})

export const organizationUsersSlice = createSlice({
	name: 'organization users',
	initialState: orgUserAdapter.getInitialState(),
	reducers: {},
	extraReducers: builder => builder
		.addCase(orgUsersRemote.list.fulfilled, (state, action) => {
			orgUserAdapter.removeAll(state)
			orgUserAdapter.addMany(state, action.payload)
		})

		.addCase(orgUsersRemote.create.fulfilled, (state, action) => {
			orgUserAdapter.addOne(state, action.payload)
		})

		.addCase(orgUsersRemote.delete.fulfilled, (state, action) => {
			orgUserAdapter.removeOne(state, action.meta.arg.userId)
		})

		.addCase(orgUsersRemote.update.fulfilled, (state, action) => {

			const {isActive, permissions} = action.meta.arg

			orgUserAdapter.updateOne(state, {
				id: action.meta.arg.userId,
				changes: {
					isActive, permissions: permissions
				}
			})
		})
})