import {useAppDispatch, useAppSelector} from "../../Store/hooks";
import useLoadingProgress from "../../Hooks/useLoadingProgress";
import useWarningPopup from "../../Hooks/useWarningPopup.hook";
import {selectActiveOrg, selectAllOrgs} from "../../Store/selectors";
import axios from "axios";
import {organizationActions} from "../../Store/organizations.slice";
import {Organization} from "../../Models";

const LeaveOrgMessage = (props: {orgName: string}) => (
	<>
		You are about this leave this organization <span style={{color: 'red'}}> {props.orgName}</span>
	</>
)

export default function DrawLeaveOrgButton() {
	const dispatch = useAppDispatch();
	const progress = useLoadingProgress();
	const warningPopup = useWarningPopup();
	const allOrgs = useAppSelector(selectAllOrgs)
	const activeOrg = useAppSelector(selectActiveOrg);

	// TODO: Test this code
	function leaveOrganization() {
		if (!activeOrg)
			return

		const notActiveOrg: Organization | undefined = allOrgs.filter(org => org !== activeOrg)[0]
		const onSuccess = () => {
			progress.start()
			axios.post(`organizations/${activeOrg.id}/users/Leave`)
				.then(() => dispatch(organizationActions.setActiveOrganization(notActiveOrg.id)))
				.finally(() => progress.stop())
		}

		warningPopup.open({
			message: <LeaveOrgMessage orgName={activeOrg.name}/>,
			onSuccess
		})
	}

	return (
		<button type="button" style={{backgroundColor: 'var(--red0)'}} onClick={leaveOrganization}>
			Leave Organization
		</button>
	)
}