import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const appStateSlice = createSlice({
	name: 'appState',
	initialState: {
		isInitializationReady: false
	},
	reducers: {
		setInitializationReady: (state, action: PayloadAction<void>) => {
			state.isInitializationReady = true
		}
	},
})

export const appActions = appStateSlice.actions