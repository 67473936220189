import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {License, LicenseWithLockedDevices, LockedDevice} from "Models";

export interface DeviceArgBase {
	licenseId: string
}

export interface CreateLicenseArgs {
	groupId: string
	planId: string
	seats: number
	expirationDate: string
}

export interface UpdateLicenseArg {
	licenseId: string
	planId: string,
	seats: number,
	isActive: boolean
	expirationDate: string
}

export default {

	get: createAsyncThunk<LicenseWithLockedDevices | null, string>('license/get', async (orgId) => {
		// This request will return "Empty" (204 status code) if said organization does not own a license yet
		const res = await axios.get<LicenseWithLockedDevices | null>(`organizations/${ orgId }/license`)
		return res.status === 200 ? res.data : null;
	}),

	create: createAsyncThunk<License, CreateLicenseArgs>('licenses/create', (data, thunkAPI) => {
		return axios.post<License>('licenses', data).then(res => res.data)
	}),

	update: createAsyncThunk<void, UpdateLicenseArg>('licenses/update', async (arg, thunkAPI) => {
		const {licenseId, ...data} = arg

		await axios.put(`licenses/${ licenseId }`, data)
	})
}