import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "index";

import AdminContextProvider from "Providers/AdminContextProvider";
import ViewModeContextProvider from "Providers/ViewModeContextProvider";
import WarningPopupProvider from "Providers/WarningPopupProvider";

import React from "react";
import { Provider } from "react-redux";
import { store } from "Store/store";

export default function ProviderStack(props: { children: any }) {
	return (
		<MsalProvider instance={ msalInstance }>
			<Provider store={ store }>
				<AdminContextProvider>
					<WarningPopupProvider>
						<ViewModeContextProvider>

							{ props.children }

						</ViewModeContextProvider>
					</WarningPopupProvider>
				</AdminContextProvider>
			</Provider>
		</MsalProvider>
	)
}