import { ChangeEvent, ReactElement, useState } from "react";
import { createPortal } from "react-dom";
import { IconWarning } from "Icons";
import { Styles } from "Popup/WarningPopup.styles";
import useWarningPopup from "Hooks/useWarningPopup.hook";
import styled from "styled-components";

export interface WarningPopupProps {
	message: string | ReactElement

	onFail?: () => void
	onSuccess?: () => void
	onChoiceCallback?: (result: boolean) => void

	challenge?: {
		title: string | ReactElement
		phrase: string
	}
}

export default function WarningPopup() {

	const [isChallengeValid, setIsChallengeValid] = useState(false)
	const {close, isOpen, currentWarn: {challenge, message, onChoiceCallback, onFail, onSuccess}} = useWarningPopup();

	function handleChallengeValueChanged(e: ChangeEvent<HTMLInputElement>) {
		setIsChallengeValid(e.target.value === challenge?.phrase)
	}

	function handleClickNo() {
		close()

		onFail?.()
		onChoiceCallback?.(false)
	}

	function handleClickYes() {
		if (!challenge || isChallengeValid) {
			close()

			onSuccess?.()
			onChoiceCallback?.(true)
		}
	}

	if (!isOpen)
		return null;

	return createPortal(
		<>
			<Background />
			<Styles>
				<header>
					<IconWarning/>
				</header>

				<div className="content">
					<h1>Warning!</h1>

					{
						challenge && (
							<div className="secret-area">
								<p> {challenge.title} </p>
								<input type="text" data-valid={isChallengeValid} pattern={challenge.phrase} onChange={handleChallengeValueChanged}/>
							</div>
						)
					}

					<p> {message} </p>

					<div className='actions'>
						<button onClick={handleClickYes} style={{backgroundColor: '#d04b4c'}}>Yes</button>
						<button onClick={handleClickNo}>No</button>
					</div>
				</div>
			</Styles>
		</>
		,
		document.body
	)
}

const Background = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
`